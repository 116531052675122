import React from "react";

interface LabelledInputProps {
    title: string;
    placeholder?: string;
    onChange?: any;
    disabled?: boolean;
    value?: string | number;
    type?: string;
    className?: string;
    name?: string;
}

const LabelledInput: React.FC<LabelledInputProps> = ({
    disabled = false,
    title,
    placeholder = "placeholder",
    onChange,
    value = "",
    type = "text",
    className = "",
    name,
}) => {
    const updateValue = (e: any) => {
        onChange(e);
    };

    return (
        <div className={"w-100 mb-3 " + className}>
            <label className="mt-1 pb-2 vertical-center" htmlFor="labelledInput">
                {title}
            </label>
            <input
                name={name}
                disabled={disabled}
                autoComplete={"new-password"}
                className="form-control input-box input-text vertical-center"
                placeholder={placeholder}
                onChange={(e: any) => {
                    e.preventDefault();
                    updateValue(e);
                }}
                value={value}
                type={type}
            ></input>
        </div>
    );
};

export default LabelledInput;
