import React, { useEffect, useState } from 'react';
import { UploadAssetsNav } from '../constants/GlobalConstants';
import "./UploadAssetsNav.css";

interface UploadProps {
  searchedView: any;
}

const UploadAssetsNaviagtor: React.FC<UploadProps> = ({ searchedView }) => {
  const [viewType, setViewType] = useState(UploadAssetsNav.PRELOAD_ASSETS.key);

  const handleChange = (e:any)=>{
    searchedView(e);
  }
  return (
    <div className="d-flex">
      <div
        onClick={() => {
          setViewType(UploadAssetsNav.PRELOAD_ASSETS.key);
          handleChange(UploadAssetsNav.PRELOAD_ASSETS.key);
        }}
        className={'row-wise ' + (viewType === UploadAssetsNav.PRELOAD_ASSETS.key ? 'upload-selected-view' : 'upload-unselected-view')}
      >
        <span>{UploadAssetsNav.PRELOAD_ASSETS.displayText}</span>
      </div>

      <span className="upload-vertical"></span>
      <div
        onClick={() => {
          setViewType(UploadAssetsNav.DECOMMISSION_ASSETS.key);
          handleChange(UploadAssetsNav.DECOMMISSION_ASSETS.key);
        }}
        className={'row-wise ' + (viewType === UploadAssetsNav.DECOMMISSION_ASSETS.key  ? 'upload-selected-view' : 'upload-unselected-view')}
      >
        <span>{UploadAssetsNav.DECOMMISSION_ASSETS.displayText}</span>
      </div>
    </div>
  );
};

export default UploadAssetsNaviagtor;
