import React from "react";
import { IVenueDrawPlotProps } from "./IVenueDrawPlotProps";
import "./_venuedrawplot.css";

export const VenueDrawPlot: React.FC<IVenueDrawPlotProps> = (props) => {
  return (
    <div className="app-card instructions-card position-relative app-card-size w-auto ms-1 p-2 m-2">
      <label className="app-card-action-heading mb-3">DRAWING INSTRUCTIONS</label>

      {/* Instructions */}

      <div className="row-wise align-items-center gap-2">
        <span className="material-icons-round instruction-icon">pan_tool_alt</span>
        <label className="drawing-instructions">
          <b>Click</b> on the <b>map</b> to <b className="color-green">create</b> a new vertex.
        </label>
      </div>

      <div className="row-wise align-items-center gap-2 mt-2">
        <span className="material-icons-round instruction-icon">touch_app</span>
        <label className="drawing-instructions">
          <b>Click</b> on the <b>vertex</b> to <b className="color-red">delete</b> it.
        </label>
      </div>

      <div className="row-wise align-items-center gap-2 mt-2">
        <span className="material-icons-round instruction-icon">pinch</span>
        <label className="drawing-instructions">
          <b>Drag</b> any <b>vertex</b> to change its position.
        </label>
      </div>

      {/* Set Color */}
      {/* <div className="row-wise vertical-center mt-3">
        <input
          id="boundaryColor"
          type="color"
          onChange={(e: any) => {
            props.setColor(e.target.value);
          }}
          value={props.color}
          className="drawing-option-color-picker"
        ></input>
        <label className="ms-2 drawing-option-label" htmlFor="boundaryColor">
          Set Boundary Color
        </label>
      </div> */}
    </div>
  );
};
