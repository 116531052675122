export const availableCountries = [
    { id: 0, name: "Afghanistan", country_code: "AF" },
    { id: 1, name: "Albania", country_code: "AL" },
    { id: 2, name: "Algeria", country_code: "DZ" },
    { id: 3, name: "Andorra", country_code: "AD" },
    { id: 4, name: "Angola", country_code: "AO" },
    { id: 5, name: "Antigua and Barbuda", country_code: "AG" },
    { id: 6, name: "Argentina", country_code: "AR" },
    { id: 7, name: "Armenia", country_code: "AM" },
    { id: 8, name: "Australia", country_code: "AU" },
    { id: 9, name: "Austria", country_code: "AT" },
    { id: 10, name: "Azerbaijan", country_code: "AZ" },
    { id: 11, name: "Bahamas", country_code: "BS" },
    { id: 12, name: "Bahrain", country_code: "BH" },
    { id: 13, name: "Bangladesh", country_code: "BD" },
    { id: 14, name: "Barbados", country_code: "BB" },
    { id: 15, name: "Belarus", country_code: "BY" },
    { id: 16, name: "Belgium", country_code: "BE" },
    { id: 17, name: "Belize", country_code: "BZ" },
    { id: 18, name: "Benin", country_code: "BJ" },
    { id: 19, name: "Bhutan", country_code: "BT" },
    { id: 20, name: "Bolivia", country_code: "BO" },
    { id: 21, name: "Bosnia and Herzegovina", country_code: "BA" },
    { id: 22, name: "Botswana", country_code: "BW" },
    { id: 23, name: "Brazil", country_code: "BR" },
    { id: 24, name: "Brunei", country_code: "BN" },
    { id: 25, name: "Bulgaria", country_code: "BG" },
    { id: 26, name: "Burkina Faso", country_code: "BF" },
    { id: 27, name: "Burundi", country_code: "BI" },
    { id: 28, name: "Cabo Verde", country_code: "CV" },
    { id: 29, name: "Cambodia", country_code: "KH" },
    { id: 30, name: "Cameroon", country_code: "CM" },
    { id: 31, name: "Canada", country_code: "CA" },
    { id: 32, name: "Central African Republic", country_code: "CF" },
    { id: 33, name: "Chad", country_code: "TD" },
    { id: 34, name: "Chile", country_code: "CL" },
    { id: 35, name: "China", country_code: "CN" },
    { id: 36, name: "Colombia", country_code: "CO" },
    { id: 37, name: "Comoros", country_code: "KM" },
    { id: 38, name: "Congo (Congo-Brazzaville)", country_code: "CG" },
    { id: 39, name: "Congo (DRC)", country_code: "CD" },
    { id: 40, name: "Costa Rica", country_code: "CR" },
    { id: 41, name: "Croatia", country_code: "HR" },
    { id: 42, name: "Cuba", country_code: "CU" },
    { id: 43, name: "Cyprus", country_code: "CY" },
    { id: 44, name: "Czechia", country_code: "CZ" },
    { id: 45, name: "Denmark", country_code: "DK" },
    { id: 46, name: "Djibouti", country_code: "DJ" },
    { id: 47, name: "Dominica", country_code: "DM" },
    { id: 48, name: "Dominican Republic", country_code: "DO" },
    { id: 49, name: "Ecuador", country_code: "EC" },
    { id: 50, name: "Egypt", country_code: "EG" },
    { id: 51, name: "El Salvador", country_code: "SV" },
    { id: 52, name: "Equatorial Guinea", country_code: "GQ" },
    { id: 53, name: "Eritrea", country_code: "ER" },
    { id: 54, name: "Estonia", country_code: "EE" },
    { id: 55, name: "Eswatini", country_code: "SZ" },
    { id: 56, name: "Ethiopia", country_code: "ET" },
    { id: 57, name: "Fiji", country_code: "FJ" },
    { id: 58, name: "Finland", country_code: "FI" },
    { id: 59, name: "France", country_code: "FR" },
    { id: 60, name: "Gabon", country_code: "GA" },
    { id: 61, name: "Gambia", country_code: "GM" },
    { id: 62, name: "Georgia", country_code: "GE" },
    { id: 63, name: "Germany", country_code: "DE" },
    { id: 64, name: "Ghana", country_code: "GH" },
    { id: 65, name: "Greece", country_code: "GR" },
    { id: 66, name: "Grenada", country_code: "GD" },
    { id: 67, name: "Guatemala", country_code: "GT" },
    { id: 68, name: "Guinea", country_code: "GN" },
    { id: 69, name: "Guinea-Bissau", country_code: "GW" },
    { id: 70, name: "Guyana", country_code: "GY" },
    { id: 71, name: "Haiti", country_code: "HT" },
    { id: 72, name: "Honduras", country_code: "HN" },
    { id: 73, name: "Hungary", country_code: "HU" },
    { id: 74, name: "Iceland", country_code: "IS" },
    { id: 75, name: "India", country_code: "IN" },
    { id: 76, name: "Indonesia", country_code: "ID" },
    { id: 77, name: "Iran", country_code: "IR" },
    { id: 78, name: "Iraq", country_code: "IQ" },
    { id: 79, name: "Ireland", country_code: "IE" },
    { id: 80, name: "Israel", country_code: "IL" },
    { id: 81, name: "Italy", country_code: "IT" },
    { id: 82, name: "Jamaica", country_code: "JM" },
    { id: 83, name: "Japan", country_code: "JP" },
    { id: 84, name: "Jordan", country_code: "JO" },
    { id: 85, name: "Kazakhstan", country_code: "KZ" },
    { id: 86, name: "Kenya", country_code: "KE" },
    { id: 87, name: "Kiribati", country_code: "KI" },
    { id: 88, name: "Korea (North)", country_code: "KP" },
    { id: 89, name: "Korea (South)", country_code: "KR" },
    { id: 90, name: "Kuwait", country_code: "KW" },
    { id: 91, name: "Kyrgyzstan", country_code: "KG" },
    { id: 92, name: "Laos", country_code: "LA" },
    { id: 93, name: "Latvia", country_code: "LV" },
    { id: 94, name: "Lebanon", country_code: "LB" },
    { id: 95, name: "Lesotho", country_code: "LS" },
    { id: 96, name: "Liberia", country_code: "LR" },
    { id: 97, name: "Libya", country_code: "LY" },
    { id: 98, name: "Liechtenstein", country_code: "LI" },
    { id: 99, name: "Lithuania", country_code: "LT" },
    { id: 100, name: "Luxembourg", country_code: "LU" },
    { id: 101, name: "Madagascar", country_code: "MG" },
    { id: 102, name: "Malawi", country_code: "MW" },
    { id: 103, name: "Malaysia", country_code: "MY" },
    { id: 104, name: "Maldives", country_code: "MV" },
    { id: 105, name: "Mali", country_code: "ML" },
    { id: 106, name: "Malta", country_code: "MT" },
    { id: 107, name: "Marshall Islands", country_code: "MH" },
    { id: 108, name: "Mauritania", country_code: "MR" },
    { id: 109, name: "Mauritius", country_code: "MU" },
    { id: 110, name: "Mexico", country_code: "MX" },
    { id: 111, name: "Micronesia", country_code: "FM" },
    { id: 112, name: "Moldova", country_code: "MD" },
    { id: 113, name: "Monaco", country_code: "MC" },
    { id: 114, name: "Mongolia", country_code: "MN" },
    { id: 115, name: "Montenegro", country_code: "ME" },
    { id: 116, name: "Morocco", country_code: "MA" },
    { id: 117, name: "Mozambique", country_code: "MZ" },
    { id: 118, name: "Myanmar", country_code: "MM" },
    { id: 119, name: "Namibia", country_code: "NA" },
    { id: 120, name: "Nauru", country_code: "NR" },
    { id: 121, name: "Nepal", country_code: "NP" },
    { id: 122, name: "Netherlands", country_code: "NL" },
    { id: 123, name: "New Zealand", country_code: "NZ" },
    { id: 124, name: "Nicaragua", country_code: "NI" },
    { id: 125, name: "Niger", country_code: "NE" },
    { id: 126, name: "Nigeria", country_code: "NG" },
    { id: 127, name: "North Macedonia", country_code: "MK" },
    { id: 128, name: "Norway", country_code: "NO" },
    { id: 129, name: "Oman", country_code: "OM" },
    { id: 130, name: "Pakistan", country_code: "PK" },
    { id: 131, name: "Palau", country_code: "PW" },
    { id: 132, name: "Palestine", country_code: "PS" },
    { id: 133, name: "Panama", country_code: "PA" },
    { id: 134, name: "Papua New Guinea", country_code: "PG" },
    { id: 135, name: "Paraguay", country_code: "PY" },
    { id: 136, name: "Peru", country_code: "PE" },
    { id: 137, name: "Philippines", country_code: "PH" },
    { id: 138, name: "Poland", country_code: "PL" },
    { id: 139, name: "Portugal", country_code: "PT" },
    { id: 140, name: "Qatar", country_code: "QA" },
    { id: 141, name: "Romania", country_code: "RO" },
    { id: 142, name: "Russia", country_code: "RU" },
    { id: 143, name: "Rwanda", country_code: "RW" },
    { id: 144, name: "Saint Kitts and Nevis", country_code: "KN" },
    { id: 145, name: "Saint Lucia", country_code: "LC" },
    { id: 146, name: "Saint Vincent and the Grenadines", country_code: "VC" },
    { id: 147, name: "Samoa", country_code: "WS" },
    { id: 148, name: "San Marino", country_code: "SM" },
    { id: 149, name: "Sao Tome and Principe", country_code: "ST" },
    { id: 150, name: "Saudi Arabia", country_code: "SA" },
    { id: 151, name: "Senegal", country_code: "SN" },
    { id: 152, name: "Serbia", country_code: "RS" },
    { id: 153, name: "Seychelles", country_code: "SC" },
    { id: 154, name: "Sierra Leone", country_code: "SL" },
    { id: 155, name: "Singapore", country_code: "SG" },
    { id: 156, name: "Slovakia", country_code: "SK" },
    { id: 157, name: "Slovenia", country_code: "SI" },
    { id: 158, name: "Solomon Islands", country_code: "SB" },
    { id: 159, name: "Somalia", country_code: "SO" },
    { id: 160, name: "South Africa", country_code: "ZA" },
    { id: 161, name: "South Sudan", country_code: "SS" },
    { id: 162, name: "Spain", country_code: "ES" },
    { id: 163, name: "Sri Lanka", country_code: "LK" },
    { id: 164, name: "Sudan", country_code: "SD" },
    { id: 165, name: "Suriname", country_code: "SR" },
    { id: 166, name: "Sweden", country_code: "SE" },
    { id: 167, name: "Switzerland", country_code: "CH" },
    { id: 168, name: "Syria", country_code: "SY" },
    { id: 169, name: "Tajikistan", country_code: "TJ" },
    { id: 170, name: "Tanzania", country_code: "TZ" },
    { id: 171, name: "Thailand", country_code: "TH" },
    { id: 172, name: "Timor-Leste", country_code: "TL" },
    { id: 173, name: "Togo", country_code: "TG" },
    { id: 174, name: "Tonga", country_code: "TO" },
    { id: 175, name: "Trinidad and Tobago", country_code: "TT" },
    { id: 176, name: "Tunisia", country_code: "TN" },
    { id: 177, name: "Turkey", country_code: "TR" },
    { id: 178, name: "Turkmenistan", country_code: "TM" },
    { id: 179, name: "Tuvalu", country_code: "TV" },
    { id: 180, name: "Uganda", country_code: "UG" },
    { id: 181, name: "Ukraine", country_code: "UA" },
    { id: 182, name: "United Arab Emirates", country_code: "AE" },
    { id: 183, name: "United Kingdom", country_code: "GB" },
    { id: 184, name: "United States", country_code: "US" },
    { id: 185, name: "Uruguay", country_code: "UY" },
    { id: 186, name: "Uzbekistan", country_code: "UZ" },
    { id: 187, name: "Vanuatu", country_code: "VU" },
    { id: 188, name: "Vatican City", country_code: "VA" },
    { id: 189, name: "Venezuela", country_code: "VE" },
    { id: 190, name: "Vietnam", country_code: "VN" },
    { id: 191, name: "Yemen", country_code: "YE" },
    { id: 192, name: "Zambia", country_code: "ZM" },
    { id: 193, name: "Zimbabwe", country_code: "ZW" }
  ];
    