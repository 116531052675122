import React from "react";
import "./SmallSecondaryButton.css";

interface SmallSecondaryButtonProps {
  label: string;
  clicked: any;
  icon? : string;
  disable?: boolean;
  className?: string;
}
const SmallSecondaryButton: React.FC<SmallSecondaryButtonProps> = ({ label, clicked, icon = "", disable = false, className = "" }) => {
  const clickAction = (e: any) => {
    if(!disable) {
      clicked(e);
    }
  };


  return (
    <div
      className={className + (disable ? " small-secondary-button-disabled" : " small-secondary-button gap-2")}
      onClick={(e: any) => {
        clickAction(e);
      }}
    >
        {icon ? <span className="material-icons-round icon">{icon}</span> : <></>}
      <span>{label}</span>
    </div>
  );
};

export default SmallSecondaryButton;
