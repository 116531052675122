import MAP_API_KEY from '../constants/map';

class GoogleMapsTileAPI {
  constructor() {
    this.apiKey = MAP_API_KEY;
    this.baseUrl = 'https://tile.googleapis.com/v1';
  }

  async createSession(mapType = 'roadmap', language = 'en-US', region = 'US') {
    const url = `${this.baseUrl}/createSession?key=${this.apiKey}`;
    const requestBody = { mapType, language, region, highDpi: true, scale: 'scaleFactor2x' };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      return await response.json();
    } catch (error) {
      console.error('Error creating session:', error);
      throw error;
    }
  }

  getTileUrl(sessionToken) {
    return `${this.baseUrl}/2dtiles/{z}/{x}/{y}?session=${sessionToken}&key=${this.apiKey}`;
  }

  async getTile(z, x, y, sessionToken, orientation = 0) {
    const url = `${this.baseUrl}/2dtiles/${z}/${x}/${y}?session=${sessionToken}&key=${this.apiKey}&orientation=${orientation}`;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      return await response.blob(); // Returns the tile image as a Blob
    } catch (error) {
      console.error('Error fetching tile:', error);
      throw error;
    }
  }
}

export { GoogleMapsTileAPI };
