import React, { useEffect, useState } from 'react';
import { CBSDStatuses, DecommissionStatuses } from '../constants/GlobalConstants';
import { exists } from '../services/helper';
import '../pages/Home.css'
import '../App.css'
import CBSDAssetFilter from './CBSDAssetFilter';
import Dropdown from 'rsuite/Dropdown';
import 'rsuite/dist/rsuite.min.css';
import { CBSDMountingTypes, UserRoles } from '../constants/GlobalConstants';
import { ApiHandler } from '../services/apiHandler';
import { useNavigate } from 'react-router-dom';

interface AssetSummaryProps {
  searchedView: any;
  summary: any;
  refreshAssets: any;
  searchFilters: any;
}

const CBSDAssetsNav: React.FC<AssetSummaryProps> = ({ searchedView, summary, refreshAssets, searchFilters }) => {

  const navigate = useNavigate();

  const [viewType, setViewType] = useState(CBSDStatuses.FIELD_TECH.key);
  const [userFilter, setUserFilter] = useState('All');
  const [installerSearchString, setInstallerSearchString] = useState('');
  const [installerUsers, setInstallerUsers] = useState<any>([]);
  const [cpiSearchString, setCpiSearchString] = useState('');
  const [cpiAdmins, setCpiAdmins] = useState<any>([]);
  const [error, setError] = useState({
    message: '',
    alert: false,
  });

  useEffect(() => {
    refreshAssets(viewType);
  }, [viewType]);


  useEffect(() => {
    getUsers(UserRoles.INSTALLER);
    getUsers(UserRoles.CPI);
  }, []);


  useEffect(() => {
    if (exists(installerSearchString)) {
      searchUser(installerSearchString, UserRoles.INSTALLER);
    } else {
      getUsers(UserRoles.INSTALLER);
    }
  }, [installerSearchString]);

  {
    /** Called on dropdown CPI search */
  }
  useEffect(() => {
    if (exists(cpiSearchString)) {
      searchUser(cpiSearchString, UserRoles.CPI);
    } else {
      getUsers(UserRoles.CPI);
    }
  }, [cpiSearchString]);


  const isFieldTech = () => {
    return exists(searchedView) ? searchedView === CBSDStatuses.FIELD_TECH.key : viewType === CBSDStatuses.FIELD_TECH.key;
  };

  const isCloud = () => {
    return exists(searchedView) ? searchedView === CBSDStatuses.CLOUD.key : viewType === CBSDStatuses.CLOUD.key;
  };

  const isSAS = () => {
    return exists(searchedView) ? searchedView === CBSDStatuses.SAS.key : viewType === CBSDStatuses.SAS.key;
  };

  const isRMA = () => {
    return exists(searchedView) ? searchedView === CBSDStatuses.RMA_REQUESTED.key : viewType === CBSDStatuses.RMA_REQUESTED.key;
  };

  const isDecommission = () => {
    return exists(searchedView)
      ? [
        DecommissionStatuses.PHYSICALLY_DECOMMISSIONED.key,
        DecommissionStatuses.DECOMMISSION_REQUESTED.key,
        DecommissionStatuses.DECOMMISSIONED.key,
      ].includes(searchedView)
      : [
        DecommissionStatuses.PHYSICALLY_DECOMMISSIONED.key,
        DecommissionStatuses.DECOMMISSION_REQUESTED.key,
        DecommissionStatuses.DECOMMISSIONED.key,
      ].includes(viewType);
  };

  const isPreloaded = () => {
    return exists(searchedView) ? searchedView === CBSDStatuses.PRELOADED.key : viewType === CBSDStatuses.PRELOADED.key;
  };

  const getUsers = (role: any) => {
    const queryParams: any = {
      page: 1,
      limit: 25,
      role: role,
    };
    ApiHandler({ apiName: 'getUsers', body: {}, params: {}, queryParams: queryParams })
      .then((response: any) => {
        if (role === UserRoles.INSTALLER) {
          setInstallerUsers(response.data.users);
        } else if (role === UserRoles.CPI) {
          setCpiAdmins(response.data.users);
        }
      })
      .catch((error: any) => {
        if (error.doLogout === true) {
          setError({ message: 'Session Timed Out', alert: true });
          setTimeout(() => navigate('/login', { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const searchUser = (user: any, role: any) => {
    const queryParams: any = {
      role: role,
      name: user,
    };
    ApiHandler({ apiName: 'searchUsers', body: {}, params: {}, queryParams: queryParams })
      .then((response: any) => {
        if (role === UserRoles.INSTALLER) {
          setInstallerUsers(response.data.users);
        } else if (role === UserRoles.CPI) {
          setCpiAdmins(response.data.users);
        }
      })
      .catch((error: any) => {
        if (error.doLogout === true) {
          setError({ message: 'Session Timed Out', alert: true });
          setTimeout(() => navigate('/login', { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };


  return (
    <div className="d-flex h-btn assetnav-main  wrap-flex">
      <div className='d-flex h-btn assetnav-main  wrap-flex bg-white'>
        <div
          onClick={() => {
            setViewType(CBSDStatuses.PRELOADED.key);
          }}
          className={'row-wise border-left-only h-btn ' + (isPreloaded() ? 'selected-view' : 'unselected-view')}
        >
          <span>{CBSDStatuses.PRELOADED.displayText}</span>
          <div className={"count-bubble " + (isPreloaded() && 'info-icon-blue')}>
            <span>{summary.preloadedCount}</span>
          </div>
        </div>

        {/* <span className="vertical"></span> */}
        <div
          onClick={() => {
            setViewType(CBSDStatuses.FIELD_TECH.key);
          }}
          className={'row-wise border-noradius h-btn ' + (isFieldTech() ? 'selected-view' : 'unselected-view')}
        >
          <span>{CBSDStatuses.FIELD_TECH.displayText}</span>
          <div className={"count-bubble " + (isFieldTech() && 'info-icon-blue')}>
            <span>{summary.fieldCount}</span>
          </div>
        </div>

        {/* <span className="vertical"></span> */}
        <div
          onClick={() => {
            setViewType(CBSDStatuses.CLOUD.key);
          }}
          className={'row-wise border-noradius h-btn ' + (isCloud() ? 'selected-view' : 'unselected-view')}
        >
          <span>{CBSDStatuses.CLOUD.displayText}</span>
          <div className={"count-bubble " + (isCloud() && 'info-icon-blue')}>
            <span>{summary.cloudCount}</span>
          </div>
        </div>

        {/* <span className="vertical"></span> */}
        <div
          onClick={() => {
            setViewType(CBSDStatuses.SAS.key);
          }}
          className={'row-wise border-noradius h-btn ' + (isSAS() ? 'selected-view' : 'unselected-view')}
        >
          <span>{CBSDStatuses.SAS.displayText}</span>
          <div className={"count-bubble " + (isSAS() && 'info-icon-blue')}>
            <span>{summary.sasCount}</span>
          </div>
        </div>

        {/* <span className="vertical"></span> */}
        <div
          onClick={() => {
            setViewType(CBSDStatuses.RMA_REQUESTED.key);
          }}
          className={'row-wise border-noradius h-btn ' + (isRMA() ? 'selected-view' : 'unselected-view')}
        >
          <span>{CBSDStatuses.RMA_REQUESTED.displayText}</span>
          <div className={"count-bubble " + (isRMA() && 'info-icon-blue')}>
            <span>{summary.rmaCount}</span>
          </div>
        </div>
        {/* <span className="vertical"></span> */}
        <div
          onClick={() => {
            setViewType(DecommissionStatuses.PHYSICALLY_DECOMMISSIONED.key);
          }}
          className={'row-wise border-right h-btn ' + (isDecommission() ? 'selected-view' : 'unselected-view')}
        >
          <span>{CBSDStatuses.DECOMMISSION.displayText}</span>
          <div className={"count-bubble " + (isDecommission() && 'info-icon-blue')}>
            <span>{summary.decommissionCount > 0 ? summary.decommissionCount : 0}</span>
          </div>
        </div>
      </div>
      <Dropdown
        disabled={searchFilters.hasSearchString}
        style={{ backgroundColor: searchFilters.hasSearchString ? '#e9ecef' : '' }}
        size="lg"
        className="menuStyle border-nopadding"        
        title={userFilter}>
        <Dropdown.Item onClick={(e: any) => setUserFilter('All')}>All</Dropdown.Item>
        <Dropdown.Menu title={UserRoles.INSTALLER}>
          <div className="userdropdown dd-align ">
            <Dropdown.Item>
              <div className="dropdown-search " >
                <span className="network-icon material-icons-round ms-2">search</span>
                <input
                  className="p-0 search-input-box"
                  value={installerSearchString}
                  placeholder="search"
                  onChange={(e: any) => {
                    setInstallerSearchString(e.target.value);
                  }}
                />
              </div>
            </Dropdown.Item>
            {installerUsers.map((user: any) => (
              <Dropdown.Item
                eventKey={`1-(${user.email})`}
                key={user.email}
                value={user.username}
                onClick={(e: any) => setUserFilter(user.username)}>
                {user.username}
              </Dropdown.Item>
            ))}
          </div>
        </Dropdown.Menu>
        <Dropdown.Menu title={UserRoles.CPI}>
          <div className="userdropdown dd-align ">
            <Dropdown.Item>
              <div className="dropdown-search">
                <span className="network-icon material-icons-round ms-2">search</span>
                <input
                  className="p-0 search-input-box"
                  value={cpiSearchString}
                  placeholder="search"
                  onChange={(e: any) => {
                    setCpiSearchString(e.target.value);
                  }}
                />
              </div>
            </Dropdown.Item>
            {cpiAdmins.map((user: any) => (
              <Dropdown.Item
                eventKey={`1-(${user.email})`}
                key={user.email}
                value={user.username}
                onClick={(e: any) => setUserFilter(user.username)}>
                {user.username}
              </Dropdown.Item>
            ))}
          </div>
        </Dropdown.Menu>
      </Dropdown>

    </div>
  );
};

export default CBSDAssetsNav;
