// Author: Rahul Choudhary
// Date: 2022-11-11
// Description:
//     -- Gets the asset details from the parent component and displays it in a modal.
//     -- Uses google maps API to display the location of the asset on a map

import React, { useEffect, useState } from 'react';
import AssetProps, { AssetObj } from '../interfaces/AssetProps';
import './DecommissionModal.css';
import Modal from './Modal';
import PrimaryButton from './PrimaryButton';
import DecommProps from '../interfaces/DecommProps';
import { ApiHandler } from '../services/apiHandler';
import { saveAs } from 'file-saver';
import { useNavigate } from 'react-router-dom';
import JSZip from 'jszip';
import DecommissionSuccessAlert from './DecommissionSuccessAlert';
import { getOrganisationId } from '../services/helper';
import Spinner from './Spinner';

interface DecommissionModalProps {
  show: boolean;
  close: () => void;
  decommAssetDetails: DecommProps | undefined;
  decommAction: (trackingId: string) => void;
}

const DecommissionModal: React.FC<DecommissionModalProps> = ({ show, close, decommAssetDetails, decommAction }) => {
  let navigate = useNavigate();
  const [decomImages, setDecomImages] = useState([]);
  const [viewImage, setViewImage] = useState({
    image: '',
    fileName: '',
    popup: false,
  }); // url of the image to be displayed in the modal
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    message: '',
    alert: false,
  });

  useEffect(() => {
    setImages(decommAssetDetails?.images);
  }, [decommAssetDetails]);
  const setImages = (images: any) => {
    setDecomImages(images);
  };
  const getImage = (path: string) => {
    setLoading(true);
    ApiHandler({ apiName: 'download', body: { path: path }, params: {} })
      .then((response: any) => {
        setViewImage({ image: response.data.file, fileName: path.slice(path.lastIndexOf('/') + 1), popup: true });
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: 'Session Timed Out', alert: true });
          setTimeout(() => navigate('/login', { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };
  const downloadImage = () => {
    try{
      saveAs(viewImage.image, `${decommAssetDetails?.tracking_id}_${viewImage.fileName}`);
    } catch (error: any) {
      console.info(error);
    }
  };

  const fetchImage = async (image: any) => {
    return new Promise((resolve: any, reject: any) => {
      ApiHandler({ apiName: 'download', body: { path: image.path }, params: {} })
        .then(async (res: any) => {
          const response = await fetch(res.data.file);
          const blob = await response.blob();
          const filename = image?.type;
          const ext = image.path.split('.').pop();
          resolve({ filename: filename, ext: ext, blob: blob });
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  };

  const downloadImageAsZip = async () => {
    setLoading(true);
    const zip = new JSZip();
    const imageFolder = zip.folder(`${decommAssetDetails?.tracking_id}_Decommission_Images`);
    if (!imageFolder) {
      console.error('Failed to create folder in zip');
      setLoading(false);
      return;
    }

    if (decomImages?.length) {
      let imageDownlaodCount = 0;
      while (imageDownlaodCount < decomImages.length) {
        let imageDetails: any = await fetchImage(decomImages[imageDownlaodCount]);
        imageFolder.file(`${imageDetails?.filename}.${imageDetails.ext}`, imageDetails.blob);
        imageDownlaodCount++;
      }

      zip.generateAsync({ type: 'blob' }).then((content: any) => {
        saveAs(content, `${decommAssetDetails?.tracking_id}_Decommission_Images.zip`);
        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  };


  const decomImageLabel: any = {
    SITE_DECOMM_SUCCESS: 'Site Decomm Success',
    DECOMM_RADIO_LABEL: 'Decomm Radio Label',
    DECOMM_MISCELLANEOUS: 'Decomm Miscellaneous',
  };
  return (
    <div>
      <Modal show={show} isNetworkActivity>
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content app-card border-0 p-0">
            <div className="modal-header">
              <h5 className="modal-title">{'DECOMMISSION'}</h5>
              <button
                type="button"
                className="btn-close"
                onClick={(e: any) => {
                  e.preventDefault();
                  setDecomImages([]);
                  close();
                }}
              ></button>
            </div>
            <div className="modal-body">
              <div className="row g-0">
                <div className="row mb-3">
                  <div className="col-8 col-wise">
                    <div className="row">
                      <div className="col-6 col-wise">
                        <span className="decom-label-bold ">
                          {getOrganisationId() !== 1 ? 'Tracking ID' : 'UID'}:
                          <span className="decom-label ms-2">{decommAssetDetails?.tracking_id}</span>
                        </span>
                        <span className="decom-label-bold mt-2 ">
                          Serial Number:<span className="decom-label ms-2">{decommAssetDetails?.serial_number}</span>
                        </span>
                      </div>
                      <div className="col-6 col-wise">
                        <span className="decom-label-bold  ">
                          Site ID:<span className="decom-label ms-2">{decommAssetDetails?.site_id}</span>
                        </span>
                        <span className="decom-label-bold mt-2 ">
                          Status:<span className="decom-label ms-2">{decommAssetDetails?.status}</span>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-4 col-wise">
                    <PrimaryButton
                      label={'Decommission'}
                      clicked={() => {
                        decommAction(decommAssetDetails?.tracking_id ?? '');
                      }}
                    />
                  </div>
                </div>
                <hr className="custom-hr ms-2" />
                <div className="row g-0">
                  <span className="decom-label-bold-blue ms-2">
                    Reason:<span className="decom-label-small ms-2">{decommAssetDetails?.reason}</span>
                  </span>

                  <span className="decom-label-bold-blue ms-2 mt-2">
                  Comments</span>
                  {decommAssetDetails?.comments?.length !== 0 && decommAssetDetails?.comments?.filter((item:any) => item?.comment)?.length ? (
                      <div className="mt-1 d-flex flex-column">
                        {decommAssetDetails?.comments
                          ?.filter((item:any) => item.comment)
                          .map((item:any, index:any) => (
                            <div key={index} className="decomm-reason-card d-flex flex-column p-2 mb-2 mt-2">
                              <div className="d-flex justify-content-between align-items-center">
                                <span className="decom-label-head p-1">
                                  {`${item.created_by} |`} <span className='decom-label-timestamp'>{`${item.role}`}</span>
                                </span>
                                <span className="decom-label-timestamp">
                                  <span>{`${item.date} `}</span>
                                  <span>{item.time}</span>
                                </span>
                              </div>
                              <hr className="custom-hr-decom" />
                              <span className="decom-reason-label p-1">{item.comment}</span>
                            </div>
                          ))}
                      </div>
                    
                  ) : (

                    <div className="mt-2">
                      <span className="no-content-text d-flex align-items-center justify-content-center py-2 my-2">~No comments available~</span>
                    </div>
                  )}
                </div>
                {decomImages?.length ? (
                  <div className="row-wise justify-content-end">
                    <div className="download-box-decom mt-3" onClick={downloadImageAsZip}>
                      <span className="material-icons-round me-2 download-icon">file_download</span>
                      <span className="download-label-decom me-3">Download Images</span>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div className="modal-body">
                  {decomImages?.filter((image: any) => ['SITE_DECOMM_SUCCESS', 'DECOMM_RADIO_LABEL', 'DECOMM_MISCELLANEOUS'].includes(image?.type))
                    ?.length ? (
                    <div className="mt-2">
                      <span className="image-title">Decommission Site Images</span>
                      <div className="d-flex flex-row flex-wrap gap-4 mt-2">
                        {decomImages
                          ?.filter((image: any) => ['SITE_DECOMM_SUCCESS', 'DECOMM_RADIO_LABEL', 'DECOMM_MISCELLANEOUS'].includes(image?.type))
                          ?.map((image: any) => {
                            return (
                              <div
                                key={image.type}
                                className="d-flex flex-column image-box-site-decom gap-2"
                                onClick={() => {
                                  getImage(image.path);
                                }}
                              >
                                <div
                                  className="d-flex align-items-center justify-content-center"
                                  style={{ background: '#F5F5F5', borderRadius: '8px', color: '#8F9FAC', height: '75px', cursor: 'pointer' }}
                                >
                                  <span className="material-icons-round">image</span>
                                </div>
                                <span className="image-list-label-decom">{decomImageLabel[image?.type]}</span>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal show={viewImage.popup}>
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content app-card border-0 p-0">
            <div className="modal-header">
              <h5 className="modal-title">{'Decommission Site Image'}</h5>
              <div className="row-wise download-box ms-2" onClick={downloadImage}>
                <span className="material-icons-round me-1 download-icon">file_download</span>
                <span className="download-label">DOWNLOAD</span>
              </div>
              <button
                type="button"
                className="btn-close"
                onClick={(e: any) => {
                  e.preventDefault();
                  setViewImage({ image: '', fileName: '', popup: false });
                }}
              ></button>
            </div>
            <div className="modal-body p-0 overflow-hidden">
              <img
                loading="eager"
                onLoad={() => {
                  setLoading(false);
                }}
                className="w-100"
                src={viewImage.image}
              />
            </div>
          </div>
        </div>
      </Modal>
      <Spinner show={loading}/>
    </div>
  );
};

export default DecommissionModal;
