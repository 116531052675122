import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ErrorAlert from "../components/ErrorAlert";
import PrimaryButton from "../components/PrimaryButton";
import Spinner from "../components/Spinner";
import SuccessAlert from "../components/SuccessAlert";
import Users from "../components/Users";
import { ApiHandler } from "../services/apiHandler";
import "./Settings.css";
import CbsdVendorCards from "../components/CbsdVendorCards/CbsdVendorCards";
import WifiVendorCards from "../components/WifiVendorCards/WifiVendorCards";
import { getTechnology } from "../services/helper";

const Settings: React.FC = () => {
  let navigate = useNavigate();
  const [aerialVendorId, setAerialVendorId] = useState<number>(0);
  const [roeVendorId, setRoeVendorId] = useState<number>(0);
  const [smbVendorId, setSmbVendorId] = useState<number>(0);
  const [wifiVendorId, setWifiVendorId] = useState<number>(0);
  const [cpeCbsdVendorId, setcpeCbsdVendorId] = useState<number>(0);
  const [cpeWifiVendorId, setcpeWifiVendorId] = useState<number>(0);

  const [aerialVendorList, setAerialVendorList] = useState<{ id: number; vendor_name: string; vendor_model: string }[]>([]);
  const [roeVendorList, setRoeVendorList] = useState<{ id: number; vendor_name: string; vendor_model: string }[]>([]);
  const [smbVendorList, setSmbVendorList] = useState<{ id: number; vendor_name: string; vendor_model: string }[]>([]);
  const [wifiVendorList, setWifiVendorList] = useState<{ id: number; vendor_name: string; vendor_model: string }[]>([]);
  const [cpeCbsdVendorList, setcpeCbsdVendorList] = useState<{ id: number; vendor_name: string; vendor_model: string }[]>([]);
  const [cpeWifiVendorList, setcpeWifiVendorList] = useState<{ id: number; vendor_name: string; vendor_model: string }[]>([]);
  const [isVendorChanged, vendorChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    message: "",
    alert: false,
  });
  const [success, setSuccess] = useState({
    message: "",
    alert: false,
  });

  useEffect(() => {
    listVendors();
    getDefaultVendors();
  }, []);

  const getDefaultVendors = () => {
    setLoading(true);
    ApiHandler({
      apiName: "getDefaultVendors",
      params: {},
      body: {},
    })
      .then((response: any) => {
        setLoading(false);
        setAerialVendorId(response.data.default_aerial_vendor_id || 0);
        setRoeVendorId(response.data.default_roe_vendor_id || 0);
        setSmbVendorId(response.data.default_smb_vendor_id || 0);
        setWifiVendorId(response.data.default_wifi_vendor_id || 0);
        setcpeCbsdVendorId(response.data.default_cpe_cbsd_vendor_id || 0);
        setcpeWifiVendorId(response.data.default_cpe_wifi_vendor_id || 0);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const saveVendorDefaultChanges = (e: any) => {
    e.preventDefault();
    setLoading(true);
    let requestBody = {
      technology_type: getTechnology() || "",
      default_aerial_vendor_id: parseInt(aerialVendorId.toString()),
      default_roe_vendor_id: parseInt(roeVendorId.toString()),
      default_smb_vendor_id: parseInt(smbVendorId.toString()),
      default_wifi_vendor_id: parseInt(wifiVendorId.toString()),
      default_cpe_cbsd_vendor_id: parseInt(cpeCbsdVendorId.toString()),
      default_cpe_wifi_vendor_id: parseInt(cpeWifiVendorId.toString()),
    };
    ApiHandler({
      apiName: "updateUserDefaults",
      params: {},
      body: requestBody,
    })
      .then((response: any) => {
        setLoading(false);
        vendorChanged(false);
        setSuccess({ message: response.data.message, alert: true });
      })
      .catch((error) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const listVendors = () => {
    setLoading(true);
    ApiHandler({ apiName: "listVendors", params: {}, body: {} })
      .then((response: any) => {
        let vendor: any = response.data;
        setAerialVendorList(vendor.aerial);
        setRoeVendorList(vendor.roe);
        setSmbVendorList(vendor.smb);
        setWifiVendorList(vendor.wifi);
        setcpeCbsdVendorList(vendor.cpe_cbsd);
        setcpeWifiVendorList(vendor.cpe_wifi);
        setLoading(false);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  return (
    <div className="backdrop col-wise overflow-auto">
      <span className="page-heading">System Settings</span>
      <div className=" row-wise h-100 gap-16">
        <div className="app-card-white w-25 h-100 space-between ">
          {/* <span className="app-card-heading">VENDORS | NAME - MODEL</span> */}

          {getTechnology() !== "wifi_6e" ? (
            <CbsdVendorCards
              aerialOptions={aerialVendorList}
              roeOptions={roeVendorList}
              smbOptions={smbVendorList}
              cpeOptions={cpeCbsdVendorList}
              selectedAerialVendorId={aerialVendorId}
              selectedRoeVendorId={roeVendorId}
              selectedSmbVendorId={smbVendorId}
              selectedCpeVendorId={cpeCbsdVendorId}
              refresh={listVendors}
              processing={(value: boolean) => {
                setLoading(value);
              }}
              onAerialChange={(value: any) => {
                setAerialVendorId(value);
                vendorChanged(true);
              }}
              onRoeChange={(value: any) => {
                setRoeVendorId(value);
                vendorChanged(true);
              }}
              onSmbChange={(value: any) => {
                setSmbVendorId(value);
                vendorChanged(true);
              }}
              onCpeChange={(value: any) => {
                setcpeCbsdVendorId(value);
                vendorChanged(true);
              }}
            />
          ) : (
            <WifiVendorCards
              selectedWifiVendorId={wifiVendorId}
              selectedCpeVendorId={cpeWifiVendorId}
              wifiOptions={wifiVendorList}
              cpeOptions={cpeWifiVendorList}
              refresh={listVendors}
              processing={(value: boolean) => {
                setLoading(value);
              }}
              onWifiChange={(value: any) => {
                setWifiVendorId(value);
                vendorChanged(true);
              }}
              onCpeChange={(value: any) => {
                setcpeWifiVendorId(value);
                vendorChanged(true);
              }}
            />
          )}

          <div className="vendor-save-button w-100 px-3 mb-3">
            <PrimaryButton label="Save Changes" clicked={saveVendorDefaultChanges} disable={!isVendorChanged} />
          </div>
        </div>

        <Users
          processing={(value: boolean) => {
            setLoading(value);
          }}
        />

        <Spinner show={loading} />
        <ErrorAlert
          show={error.alert}
          onDismiss={() => {
            setError({ message: "", alert: false });
          }}
          message={error.message}
        />
        <SuccessAlert
          show={success.alert}
          onDismiss={() => {
            setSuccess({ message: "", alert: false });
          }}
          message={success.message}
        />
      </div>
    </div>
  );
};

export default Settings;
