import React from 'react';

const CBSDAssetTableHeader: React.FC = () => {
  return (
    <div className="row table-header-card vertical-center mt-3 ">
      <div className="col ">
        <b>{'Tracking ID / UID'} </b>
      </div>
      <div className="col border-left-line">
        <b>Serial Number</b>
      </div>
      <div className="col border-left-line">
        <b>CBRS Node Name</b>
      </div>
      <div className="col border-left-line">
        <b>Site ID</b>
      </div>
      <div className="col border-left-line">
        <b>Mounting Type</b>
      </div>
      <div className="col border-left-line">
        <b>CBSD Category</b>
      </div>
      <div className="col border-left-line">
        <b>Installer</b>
      </div>
      <div className="col border-left-line">
        <b>Last Modified (UTC)</b>
      </div>
      <div className="col border-left-line">
        <b>Age (in Days)</b>
      </div>
    </div>
  );
};

export default CBSDAssetTableHeader;
