import React, { useEffect, useState } from 'react';
import './ImportDataModal.css';
import Modal from './Modal';
import Dropzone from 'react-dropzone';
import { UploadAssetsNav } from '../constants/GlobalConstants';
import UploadAssetsNaviagtor from './UploadAssetsNav';
import SmallSecondaryButton from './SmallSecondaryButton';
import { ApiHandler } from '../services/apiHandler';
import Spinner from './Spinner';

interface UploadModalProps {
  show: boolean;
  close: () => void;
  uploadAPI: (api: 'validateAndCreateUploadFile'|'validateAndCreateDecommUploadFile') => void;
  onUpload: (files: any) => void;
}

const UploadModal: React.FC<UploadModalProps> = ({ show, close, uploadAPI, onUpload }) => {
  const [templateFileName,setTemplateFileName] = useState(UploadAssetsNav.PRELOAD_ASSETS.key);
  const [loading, setLoading] = useState(false);
  const handleSearchViewChange = (e:any)=>{
    setTemplateFileName(e);
  }

  useEffect(()=>{
    if(templateFileName===UploadAssetsNav.PRELOAD_ASSETS.key){
      uploadAPI('validateAndCreateUploadFile')
    }else{
      uploadAPI('validateAndCreateDecommUploadFile')
    }
  },[templateFileName])

  const handleDownload = ()=>{
    const XlsxUrl = templateFileName ===  UploadAssetsNav.PRELOAD_ASSETS.key ? `${process.env.PUBLIC_URL}/assets/spectrapro-preload-template.xlsx`
    :
    `${process.env.PUBLIC_URL}/assets/spectrapro-decommission-template.xlsx` 
    ;

    const link = document.createElement("a");
    link.href = XlsxUrl;
    link.download = `spectrapro-${templateFileName === UploadAssetsNav.PRELOAD_ASSETS.key ? "preload" : "decommission"}-template.xlsx` ; 
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  // const handleDownload = async () => {
  //   setLoading(true);
  //   return new Promise((resolve: any, reject: any) => {
  //     ApiHandler({ apiName: 'download_template', body: {},params:{}, queryParams: {type:templateFileName} })
  //       .then(async (res: any) => {
          
  //         const response = await fetch(res.data.file)
  //         const blob = await response.blob() 
  //         const filename = `spectrapro-${templateFileName === UploadAssetsNav.PRELOAD_ASSETS.key ? "preload" : "decommission"}-template`; 
  //         const ext = 'xlsx'
  //         resolve({ filename, blob,ext });
  //         const url = window.URL.createObjectURL(blob);
  //         const link = document.createElement('a');
  //         link.href = url;
  //         link.download = `${filename}.${ext}`;
  //         document.body.appendChild(link);
  //         link.click();
  //         link.parentNode?.removeChild(link);
  //         window.URL.revokeObjectURL(url);
  //         setLoading(false);
  //       })
  //       .catch((error: any) => {
  //         reject(error);
  //       });
  //   });
  // };
  
  return (
    <Modal show={show}>
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content app-card border-0 p-0">
          <div className="modal-header">
            <h5 className="modal-title">{'IMPORT DATA'}</h5>

            <div className="d-flex flex-row align-items-center gap-3">
              <button
                type="button"
                className="btn-close"
                onClick={(e: any) => {
                  e.preventDefault();
                  close();
                }}
              ></button>
            </div>
          </div>
          <div className="modal-body">
            <UploadAssetsNaviagtor searchedView={handleSearchViewChange}/>
            <div className='d-flex justify-content-end py-2'>
              <SmallSecondaryButton label={'Download Template'} icon='download' clicked={handleDownload}></SmallSecondaryButton>
            </div>
            <Dropzone
              multiple={false}
              onDrop={(acceptedFiles: any) => {
                onUpload(acceptedFiles);
              }}
              accept={{ 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'], 'application/vnd.ms-eXlsx': ['.xlsx'] }}
            >
              {({ getRootProps, getInputProps }) => (
                <section className="w-100">
                  <div
                    {...getRootProps({
                      className: 'file-dropzone',
                    })}
                  >
                    <input {...getInputProps()} />
                    <div className="">
                      <span className="material-icons-round upload-icon">cloud_upload</span>
                      <p>Drag 'n' drop some files here, or click to select files</p>
                      <em>(Only .xlsx files are allowed)</em>
                    </div>
                  </div>
                  <div></div>
                </section>
              )}
            </Dropzone>
          </div>
        </div>
      </div>
      <Spinner show={loading} />
    </Modal>
  );
};

export default UploadModal;
