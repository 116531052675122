import React from "react";
import "./SecondaryButton.css";

interface SecondaryButtonProps {
  label: string;
  clicked: any;
  disable?: boolean;
  className?: string;
  icon?: string | React.ReactNode;
  type?: "delete" | "normal";
}
const SecondaryButton: React.FC<SecondaryButtonProps> = ({
  label,
  clicked,
  disable = false,
  className = "",
  icon,
  type = "normal",
}) => {
  const clickAction = (e: any) => {
    if(!disable) {
      clicked(e);
    }
  };


  const checkDisable = (disable:any,type:any) => {
    if(disable) {
      return " row-wise secondary-button-disabled"
    }
    else if(type === "delete") {
      return " row-wise secondary-delete-button"
    }
    return " row-wise secondary-button"
  }
  return (
    <div
      className={
        className +
        (checkDisable(disable,type))
      }
      onClick={(e: any) => {
        clickAction(e);
      }}
    >
      {/* {icon ? <span className="material-icons-round me-2 font-20">{icon}</span> : ""} */}
      {icon && <span className="me-2"> {icon}</span>}
      <span >{label}</span>
    </div>
  );
};

export default SecondaryButton;
