import AddAssetProps, { AddSectorProps } from '../interfaces/AddAssetProps';

export const DEFAULT_ASSET: AddAssetProps = {
  tracking_id: '',
  gnodeb_id:'',
  site_id: '',
  cbrs_node_name: '',
  description: '',
  latitude: 0,
  longitude: 0,
  serial_number: '',
  device_mac: '',
  device_pn: '',
  cm_serial_number: '',
  cm_mac: '',
  cm_pn: '',
  region: '',
  status: 'Field Tech',
  images: [],
  vendor_id: 0,
  mounting_type: '',
  height: 0,
  sectors: [],
  venue_id: null,
  building_id: null,
  floor_id: null,
  deployment_location: 'outdoor',
  technology_type: 'cbrs_5g',
};

export const DEFAULT_SECTOR: AddSectorProps = {
  sector_id: '',
  tracking_id: '',
  status: 'Field Tech',
  height: 0,
  height_type: 'HEIGHT_TYPE_AGL',
  horizontal_accuracy: '',
  vertical_accuracy: '',
  haat: '0',
  azimuth: 0,
  downtilt: '0',
  azimuth_image: '',
  downtilt_image: '',
  height_image: '',
  measurement_capability: 'MSRMT_WITHOUT_GRANT',
  deployment_type: 'outdoor',
  call_sign: '1',
  grouping_params: '1',
  supported_spec: '',
};
