import React, { useState } from "react";
import AssetProps from "../interfaces/AssetProps";
import "./AssetDetails.css";
import AssetDetailsPanel from "./AssetDetailsPanel";
import AssetNetworkTest from "./AssetNetworkTest";
import AssetParameters from "./AssetParameters";
import Spinner from "./Spinner";

interface AssetDetailsProps {
  goBack: any;
  asset: AssetProps | undefined;
  setAction: any;
  rmaSerialNumber: any;
  setUpdatedAsset: any;
  refreshAsset: any;
}

const AssetDetails: React.FC<AssetDetailsProps> = ({
  rmaSerialNumber,
  goBack,
  asset,
  setAction,
  setUpdatedAsset,
  refreshAsset,
}) => {
  const [activeTab, setActiveTab] = useState("parameter");
  const [loading, setLoading] = useState(false);

  return (
    <div className="h-100 overflow-auto row-wise justify-content-between">
      {/* Card for the left side details */}
      <AssetDetailsPanel rmaSerialNumber={rmaSerialNumber} assetDetails={asset} goBack={goBack} setAction={setAction} />

      {/* card for the right side details */}
      <div className="plain-card position-relative  bg-card ">
        <div className="row-wise justify-content-evenly mt-2 bg-card ">
          <div
            className={ activeTab === "parameter" ? "active-tab" : "inactive-tab"}
            onClick={() => {
              setActiveTab("parameter");
            }}
          >
            Parameters
          </div>
          <div
            className={activeTab === "network-test" ? "active-tab" : "inactive-tab"}
            onClick={() => {
              setActiveTab("network-test");
            }}
          >
            Network Test
          </div>
        </div>
        {activeTab === "parameter" ? (
          <AssetParameters
            processing={(value: boolean) => {
              setLoading(value);
            }}
            setUpdatedAsset={setUpdatedAsset}
            refreshAsset={refreshAsset}
            assetDetails={asset}
          />
        ) : (
          <AssetNetworkTest />
        )}
      </div>

      <Spinner show={loading} />
    </div>
  );
};

export default AssetDetails;
