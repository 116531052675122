import React, { useState } from "react";
import { ICbsdVendorCardsProps } from "./ICbsdVendorCardsProps";
import Vendor from "../Vendor";
import { exists, getOrganisationId } from "../../services/helper";
import './CbsdVendorCards.css'

const CbsdVendorCards: React.FC<ICbsdVendorCardsProps> = (props) => {

  const [activeTab, setActiveTab] = useState<"cbsd" | "cpe">("cbsd");

  return (
    <div className="py-2">
      <div className="vendor-title text-color px-3">Vendor</div>
      {/* Vendor Type selection tabs */}
      <div className="mt-3 row-wise w-100 px-3">
        <div
          id="cbsd-vendors"
          onClick={() => {
            setActiveTab("cbsd");
          }}
          className={'border-left w-50 vertical-center text-color center cursor-pointer ' + `${activeTab === "cbsd" ? "border-active " : "border-inactive "}`}
        >
          {"CBSD"}
        </div>
        {getOrganisationId() != 1 ? (
          <div
            id="cpe-vendors"
            onClick={() => {
              setActiveTab("cpe");
            }}
            className={'border-right w-50 vertical-center text-color center cursor-pointer ' + `${activeTab === "cpe" ? "border-active " : "border-inactive "}`}
          >
            {"CPE"}
          </div>
        ) : (
          <></>
        )}
      </div>

      {activeTab === "cbsd" ? (
        <div className=" gx-0">
          <div className="ps-3 pe-3">
            <Vendor
              title="Aerial"
              type="cbrs_5g"
              processing={props.processing}
              selectedVendorId={props.selectedAerialVendorId}
              options={props.aerialOptions}
              refresh={props.refresh}
              onChange={props.onAerialChange}
            />
          </div>

          <div className="ps-3 pe-3">
            <Vendor
              title="ROE"
              type="cbrs_5g"
              selectedVendorId={props.selectedRoeVendorId}
              processing={props.processing}
              options={props.roeOptions}
              refresh={props.refresh}
              onChange={props.onRoeChange}
            />
          </div>

          <div className=" ps-3 pe-3">
            <Vendor
              title="SMB"
              type="cbrs_5g"
              selectedVendorId={props.selectedSmbVendorId}
              processing={props.processing}
              options={props.smbOptions}
              refresh={props.refresh}
              onChange={props.onSmbChange}
            />
          </div>
        </div>
      ) : activeTab === "cpe" ? (
        <div className= "gx-0">
          <div className="ps-3 pe-3">
            <Vendor
              title="CPE"
              type="cpe_cbsd"
              processing={props.processing}
              selectedVendorId={props.selectedCpeVendorId}
              options={props.cpeOptions}
              refresh={props.refresh}
              onChange={props.onCpeChange}
            />
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CbsdVendorCards;
