import React from "react";
import "./homeActions.css";
import AddAsset from "../assets/addAsset.svg";
import AddAssetWhite from "../assets/CBSD.svg"
interface HomeActionsProps {
  label: string;
  action: string;
  clicked: any;
}

const MeasureIcon: React.FC<{ action: string }> = ({ action }) => {
  return action === "measure" ? (
    <span className="material-icons-round me-2" style={{ color: "#ff9444" }}>
      straighten
    </span>
  ) : (
    <CalculateIcon action={action} />
  );
};

const CalculateIcon: React.FC<{ action: string }> = ({ action }) => {
  return action === "calculate" ? (
    <span className="material-icons-outlined me-2" style={{ color: "#fad36b" }}>
      calculate
    </span>
  ) : (
    <></>
  );
};

const HomeActions: React.FC<HomeActionsProps> = ({ label, action, clicked }) => {
  return (
    <div className="action-box col-12 ps-1 pe-1 center" onClick={clicked}>
      {action === "add" ? <img src={AddAssetWhite} className="h-20 me-2" /> : <MeasureIcon action={action} />}
      <span className="action-label">{label}</span>
    </div>
  );
};

export default HomeActions;
