import React from 'react';

const AuditLogHeader: React.FC = () => {
  return (
    <div className="row table-header-card vertical-center mt-1 ">
      <div className="col">
        <span>Parameter</span>
      </div>
      <div className="col">
        <span>Sector ID</span>
      </div>
      <div className="col">
        <span>Initial Value</span>
      </div>
      <div className="col">
        <span>Final Value</span>
      </div>
      <div className="col">
        <span>Updated By</span>
      </div>
      <div className="col">
        <span>Date (UTC)</span>
      </div>
    </div>
  );
};

export default AuditLogHeader;
