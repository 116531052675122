import LabelLessDropdown from './LabelLessDropdown';
import Modal from './Modal';
import PrimaryButton from './PrimaryButton';
import Decommission_Success from '../assets/Decommissioned-Success.svg';
import React, { useState } from 'react';
interface DecommissionSuccessAlertProps {
  visible: boolean;
  title: string;
  sub_title: String;
  primaryText: string;
  primaryAction: any;
}

const DecommissionSuccessAlert: React.FC<DecommissionSuccessAlertProps> = ({ visible, title, sub_title, primaryAction, primaryText }) => {
  return (
    <Modal show={visible}>
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content app-card border-0 p-1">
          <div className="modal-header mb-0 center">
            <div className="d-flex flex-column gap-2 ">
              <h5 className="modal-title mb-3 center">{title}</h5>
              <span className="modal-sub-title">{sub_title}</span>
              <div className="center mt-3 mb-2">
                <img src={Decommission_Success}></img>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div style={{ flex: '1' }}>
              <PrimaryButton label={primaryText} disable={false} clicked={primaryAction} />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DecommissionSuccessAlert;
