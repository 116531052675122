import { useEffect, useState, useRef } from "react";
import { createPortal } from "react-dom";

interface PopupProps {
  map: google.maps.Map;
  position: google.maps.LatLngLiteral;
  children: any;
  visible: boolean;
}

const CustomPopup: React.FC<PopupProps> = ({ map, position, children, visible }) => {
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const overlayRef = useRef<google.maps.OverlayView | null>(null);

  useEffect(() => {
    if (!map || !visible) return;

    const containerDiv = document.createElement("div");
    containerDiv.classList.add("popup-container");



    class Popup extends google.maps.OverlayView {
      
      onAdd() {
        this.getPanes()?.floatPane.appendChild(containerDiv);
      }

      onRemove() {
        containerDiv.parentElement?.removeChild(containerDiv);
      }

      draw() {
        const projection = this.getProjection();
        if (!projection) return;
        console.log(position, "cotainerdiv")

        const divPosition = projection.fromLatLngToDivPixel(
          position
        );
        if (!divPosition) return;

        containerDiv.style.left = `${divPosition.x}px`;
        containerDiv.style.top = `${divPosition.y}px`;
        containerDiv.style.position = 'absolute';
        containerDiv.style.opacity = '.8'
        console.log(divPosition, "cotainerdiv")

      }
    }

    Popup.preventMapHitsAndGesturesFrom(containerDiv);


    console.log(containerDiv, "cotainerdiv")
    const popup = new Popup();
    popup.setMap(map);
    overlayRef.current = popup;
    containerDiv.append(children)
    setContainer(containerDiv);

    // return () => popup.setMap(null); // Cleanup
  }, [map, position, visible]);

  return container && visible ? createPortal(children, container) : null;
};

export default CustomPopup;
