import React, { useEffect, useState } from "react";
import { IAddBuildingProps } from "./IAddBuildingprops";
import LabelledInput from "../LabelledInput";
import PrimaryButton from "../PrimaryButton";
import SecondaryButton from "../SecondaryButton";
import Dropzone from "react-dropzone";
import "./_addbuilding.css";
import { ApiHandler } from "../../services/apiHandler";
import Spinner from "../Spinner";
import ConfirmAlert from "../confirmAlert";
import ErrorAlert from "../ErrorAlert";
import SuccessAlert from "../SuccessAlert";
import WarningAlert from "../WarningAlert";
import { useNavigate } from "react-router-dom";
import { CloseOutlined, DeploymentUnitOutlined, DownloadOutlined, DownOutlined, GlobalOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Dropdown, MenuProps, Select } from "antd";
import { Building } from "../../custom_icons/Building";
import { PenIcon } from "../../custom_icons/PenTool";
import LabelLessInput from "../LabelLessInput";
import { VenueDrawPlot } from "../VenueDrawPlot/VenueDrawPlot";

export const AddBuilding: React.FC<IAddBuildingProps> = (props) => {
  let navigate = useNavigate();
  const [confirmAlert, setConfirmAlert] = useState({ alert: false, message: "" });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    message: "",
    alert: false,
  });
  const [warning, setWarning] = useState({
    message: "",
    alert: false,
  });
  const [success, setSuccess] = useState({
    message: "",
    alert: false,
  });
  const [building, setBuilding] = useState({
    name: "",
    description: "",
    address: "",
    latitude: 0,
    longitude: 0,
    image: undefined,
    total_floors: 0,
    floors_in_use: 0,
    height: 0,
    height_unit: 'm',
    elevation: 0,
    elevation_unit: 'm'
  });
  const [venue, setVenue] = useState<any>();
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [isDrawActive, setIsDrawActive] = useState(false);
  const [isCreateActive, setIsCreateActive] = useState(false);
  const [assetsInBuilding, setAssetsInBuilding] = useState<any[]>([]);

  useEffect(() => {
    if (isCreateActive) {
      checkAssetsInBuilding();
    }
  }, [props.assetsInBounds]);

  useEffect(() => {
    setBuilding((prevValues: any) => {
      return {
        ...prevValues,
        latitude: parseFloat(props.position.lat.toFixed(6)),
        longitude: parseFloat(props.position.lng.toFixed(6)),
      };
    });
  }, [props.position]);

  useEffect(() => {
    if (venue !== undefined) {
      checkLocationInPolygon();
    }
  }, [props.position]);

  useEffect(() => {
    getVenue();
  }, [props.venueId]);



  const checkAssetsInBuilding = () => {
    if (props.assetsInBounds.length > 0) {
      let assetsInBuildingList: any[] = [];
      for (let asset of props.assetsInBounds) {
        const contains: any = window.google.maps.geometry.poly.containsLocation(
          new window.google.maps.LatLng(asset.latitude, asset.longitude),
          new window.google.maps.Polygon({ paths: props.boundaryPolygon })
        );
        if (contains) {
          assetsInBuildingList.push(asset);
        }
      }
      setAssetsInBuilding(assetsInBuildingList);
      if (assetsInBuildingList.length > 0) {
        let confirmMessage: string =
          "<b>Assets with following Tracking IDs fall inside the bounds of Building.</b><br/><br/><ul>";
        assetsInBuildingList.forEach((asset: any) => {
          confirmMessage += "<li>" + asset.tracking_id + "</li>";
        });
        confirmMessage +=
          "</ul><i>On confirming the creation, the assets will <b>automatically be associated to the Building</b>.</i><br/><br/><span>Do you want to proceed?</span>";
        setConfirmAlert({ alert: true, message: confirmMessage });
      } else {
        setConfirmAlert({ alert: true, message: "Are you sure you want to finalize the Building creation?" });
      }
    } else {
      setConfirmAlert({ alert: true, message: "Are you sure you want to finalize the Building creation?" });
    }
    setIsCreateActive(false);
  };


  const checkLocationInPolygon = () => {
    const contains: any = window.google.maps.geometry.poly.containsLocation(
      new window.google.maps.LatLng(props.position.lat, props.position.lng),
      new window.google.maps.Polygon({ paths: venue?.polygon_points })
    );
    if (!contains) {
      setIsSaveDisabled(true);
      setWarning({
        message: "Please select a location within the venue",
        alert: true,
      });
    } else {
      setIsSaveDisabled(false);
      setBuilding((prevValues: any) => {
        return {
          ...prevValues,
          latitude: parseFloat(props.position.lat.toFixed(6)),
          longitude: parseFloat(props.position.lng.toFixed(6)),
        };
      });
    }
  };

  const getVenue = () => {
    setLoading(true);
    ApiHandler({ apiName: "getVenue", body: {}, params: { venue_id: props.venueId } })
      .then((res: any) => {
        setLoading(false);
        setVenue(res.data);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error?.data?.message, alert: true });
        }
      });
  };

  const handleInputChange = (e: any) => {
    setBuilding((prevValues: any) => {
      return { ...prevValues, [e.target.name]: e.target.value };
    });
  };

  const setBoundaryPolygonToCreatBuilding = () => {
    // set boundary plot from google.maps.LatLng to {lat: number, lng: number}
    let boundary = props.boundaryPolygon?.map((point) => {
      return {
        lat: parseFloat(point.lat().toFixed(6)),
        lng: parseFloat(point.lng().toFixed(6)),
      };
    });
    // push first vertex as last vertex to make it a closed polygon
    boundary?.push(boundary[0]);
    return boundary;
  }

  const createBuilding = () => {
    setLoading(true);
    let boundary = setBoundaryPolygonToCreatBuilding();
    let formData = new FormData();
    formData.append("venue_id", props.venueId);
    formData.append("name", building.name);
    formData.append("description", building.description);
    formData.append("address", building.address);
    formData.append("latitude", building.latitude.toString());
    formData.append("longitude", building.longitude.toString());
    formData.append("total_floors", building.total_floors.toString());
    if(boundary)
      formData.append('boundary_plot',boundary.toString())
    if (building.image !== undefined) {
      formData.append("image", building.image);
    }
    ApiHandler({ apiName: "createBuilding", body: formData, params: {} })
      .then((res: any) => {
        setLoading(false);
        setSuccess({
          message: "Building Added Successfully",
          alert: true,
        });
        setTimeout(() => {
          props.navigateTo("venueDetails");
        }, 1000);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const items: MenuProps['items'] = [
    {
      key: 'venue',
      label: 'Venue',
      icon: <GlobalOutlined />
    },
    {
      key: 'market',
      label: 'Market',
      icon: <DeploymentUnitOutlined />
    },
    {
      key: 'building',
      label: 'Building',
      icon: <Building />
    },
  ];

  const heightUnitOptions = [
    {
      lable: 'm',
      value: 'm'
    },
    {
      lable: 'ft',
      value: 'ft'
    }
  ]

  const drawBoundary = () => {
    setIsDrawActive(true);
    props.setBoundaryDrawMode("draw");
  };

  const confirmBoundary = () => {
    setIsDrawActive(false);
    props.setBoundaryDrawMode(null);
  };

  const cancelBoundary = () => {
    setIsDrawActive(false);
    props.cancelBoundaryDraw();
  };
  const startBuildingVenue = () => {
    console.log(props.boundaryPolygon, "boundary poly")
    if (typeof props.boundaryPolygon == 'undefined' || props.boundaryPolygon.length == 0) {
      setWarning({
        message: "Boundary of the Building is not defined yet. Click on the 'Draw Boundaries' button to start.",
        alert: true,
      });
      return;
    }
    setIsCreateActive(true);
    setConfirmAlert({ alert: true, message: "Are you sure you want to finalize the building creation?" });
    props.fitVenueOnMap();
  };


  return (
    <div className="h-100 row-wise overflow-auto">
      <div className="right row-wise ">

        {/* Plot Boundary */}
        {isDrawActive && <VenueDrawPlot setColor={() => {}} color={''} />}

        <div className="app-card app-card-size ">
          {/* <div className="row-wise vertical-center">
          <div
            className="icon-background-active"
            onClick={() => {
              props.navigateTo("venueDetails");
            }}
          >
            <span className="material-icons-round icon-active">arrow_back</span>
          </div>

          <span className="app-card-action-heading ms-3">ADDING BUILDING</span>
           </div> */}
          <div className="row-wise vertical-center space-between px-4 py-3">
            <span className="app-card-action-heading fw-bold"> New</span>  {/* //ADDING CBSD */}
            <div
              // className="icon-background-active"
              onClick={() => {
                console.log(props.navigateFrom, "navigatefrom")
                cancelBoundary()
                props.navigateTo(props.navigateFrom);
              }}>
              <CloseOutlined className='fs-6 cursor-pointer' />
              {/* <span className="material-icons-round icon-active">arrow_back</span> */}
            </div>
          </div>
          <div className='border-bottom' />
          <div className="overflow-auto">
            <div className="px-4 py-2">

              <div className='text-color'>
                Layer type
              </div>

              <div className='pt-2'>
                <Dropdown disabled menu={{ items }} trigger={['click']}>
                  <div className='border row-wise space-between vertical-center background-white' onClick={(e) => e.preventDefault()}>
                    <span> <Building /> &nbsp; Building </span>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </div>
              <div>
                <div className='text-color mt-3 mb-3'>
                  Building Settings
                </div>
                {isDrawActive ? (
                  <div className="row gx-2 me-4 ms-1">
                    <div className="col-6">
                      <SecondaryButton
                        className="my-2"
                        label={"Confirm Boundary"}
                        clicked={confirmBoundary}
                        disable={false}
                      />
                    </div>
                    <div className="col-6">
                      <SecondaryButton
                        className="my-2"
                        label={"Cancel"}
                        clicked={cancelBoundary}
                        disable={false}
                        type="delete"
                      />
                    </div>
                  </div>
                ) :
                  <div className="button-half">
                    <SecondaryButton
                      className="mb-2 me-4 ms-1 mt-2 info-icon-blue"
                      label={"Draw boundaries"}
                      clicked={drawBoundary} //drawBoundary
                      disable={false}
                      icon={<PenIcon style={{ color: '#1677FF' }} />}
                    />
                  </div>
                }

                <div className="button-half">
                  <SecondaryButton
                    className="mb-2 me-4 ms-1 mt-2 info-icon-blue"
                    label={"Import 3D Model"}
                    clicked={() => { }} //drawBoundary
                    disable={false}
                    icon={<DownloadOutlined style={{ color: '#1677FF' }} />}
                  />
                </div>

              </div>
            </div>
            <div className='border-bottom' />
            <div className="px-4 py-2">
              <div className='text-color my-1'>
                Building Specifications
              </div>
              <div className='text-color mb-2 mt-3'>
                Name
              </div>
              <LabelLessInput
                name="name"
                className="w-100"
                changeVal={(e: any) => {
                  handleInputChange(e);
                }}
                value={building.name}
                placeholder="Enter name "
              />
              <div className="row row-cols-2 ">
                <div className="col">
                  <div className='text-color mb-2 mt-3'>
                    Total Floors
                  </div>
                  <LabelLessInput
                    name="total_floors"
                    changeVal={(e: any) => {
                      handleInputChange(e);
                    }}
                    type="number"
                    value={building.total_floors}
                    placeholder="Enter total floors "
                    w100
                  />
                </div>
                <div className="col">
                  <div className='text-color mb-2 mt-3'>
                    Floors in Use
                  </div>
                  <LabelLessInput
                    name="floors_in_use"
                    changeVal={(e: any) => {
                      handleInputChange(e);
                    }}
                    type="number"
                    value={building.floors_in_use}
                    placeholder="Enter total floors "
                    w100
                  />
                </div>
              </div>
              <div className='text-color mb-2 mt-3'>
                Building Height <span className="text-color-secondary">(optional)</span>
              </div>
              <div className="d-flex gap-8">
                <LabelLessInput
                  name="height"
                  changeVal={(e: any) => {
                    handleInputChange(e);
                  }}
                  type="number"
                  value={building.height}
                  placeholder="Specify Height "
                />
                <Select className="flex-grow-1" options={heightUnitOptions} value={building.height_unit} onChange={(e) => {
                  setBuilding((prevValues: any) => {
                    return { ...prevValues, ['height_unit']: e }
                  });
                }}
                />
              </div>
              <div className='text-color mb-2 mt-3'>
                Elevation <QuestionCircleOutlined className="text-color-secondary" />
              </div>
              <div className="d-flex gap-8">
                <LabelLessInput
                  name="elevation"
                  changeVal={(e: any) => {
                    handleInputChange(e);
                  }}
                  type="number"
                  value={building.elevation}
                  placeholder="Specify Elevation "
                />
                <Select className="flex-grow-1" options={heightUnitOptions} value={building.elevation_unit} onChange={(e) => {
                  setBuilding((prevValues: any) => {
                    return { ...prevValues, ['elevation_unit']: e }
                  });
                }}
                />
              </div>
              <div className="row gx-2">
                <div className="col-6">
                  <div className='text-color mb-2 mt-3'>
                    Latitude
                  </div>
                  <LabelLessInput
                    changeVal={(e: any) => {
                      handleInputChange(e);
                    }}
                    placeholder="Latitude"
                    name="latitude" disabled value={building.latitude} w100 />
                </div>
                <div className="col-6">
                  <div className='text-color mb-2 mt-3'>
                    Longitude
                  </div>
                  <LabelLessInput
                    changeVal={(e: any) => {
                      handleInputChange(e);
                    }}
                    placeholder="Longitude"
                    name="longitude" disabled value={building.longitude} w100 />
                </div>
              </div>
            </div>

            {/* <form className="overflow-auto col-wise h-100 pe-3 ps-1 pb-1 mb-1 pt-2">
            <LabelledInput
              title="Name*"
              name="name"
              onChange={(e: any) => {
                handleInputChange(e);
              }}
              value={building.name}
            />
            <LabelledInput
              title="Total Number of Floors*"
              name="total_floors"
              onChange={(e: any) => {
                handleInputChange(e);
              }}
              type="number"
              value={building.total_floors}
            />
            <div className="form-floating w-100 mb-2">
              <textarea
                rows={5}
                id="description"
                className="form-control textbox-box input-text h-auto"
                placeholder={"Description"}
                name="description"
                onChange={(e: any) => {
                  handleInputChange(e);
                }}
                value={building.description}
              ></textarea>
              <label className="input-label" htmlFor="description">
                Description
              </label>
            </div>
                  */}
            {/* Image Upload */}
            {/*    <Dropzone
              multiple={false}
              onDrop={(acceptedFiles: any) => {
                setBuilding((prevValues: any) => {
                  return { ...prevValues, image: acceptedFiles[0] };
                });
                console.log(acceptedFiles[0]);
              }}
              accept={{ "image/jpg": [".jpg"], "image/jpeg": [".jpeg"], "image/png": [".png"] }}
            >
              {({ getRootProps, getInputProps }) => (
                <section className="img-dropzone-set mb-2">
                  <div
                    {...getRootProps({
                      className: `${building.image !== undefined ? "img-building-uploaded" : "img-building"}`,
                    })}
                  >
                    <input {...getInputProps()} />

                    {building.image === undefined ? (
                      <div className="row-wise vertical-center ps-2 w-100 h-100">
                        <span className="material-icons-outlined">add_a_photo</span>
                        <span className="ps-2">Upload Building Image</span>
                      </div>
                    ) : (
                      <div className="row-wise vertical-center ps-2 w-100 h-100">
                        <span className="material-icons-outlined">photo_camera</span>
                        <span className="ps-2">{building.image["name"]}</span>
                      </div>
                    )}
                  </div>
                  <div></div>
                </section>
              )}
            </Dropzone>

            <div className="form-floating w-100 mb-2">
              <textarea
                rows={5}
                id="address"
                className="form-control textbox-box input-text h-auto"
                placeholder={"Address"}
                name="address"
                onChange={(e: any) => {
                  handleInputChange(e);
                }}
                value={building.address}
              ></textarea>
              <label className="input-label" htmlFor="address">
                Address*
              </label>
            </div>

            <div className="row gx-2">
              <div className="col-6">
                <LabelledInput title="Latitude*" name="latitude" disabled value={building.latitude} />
              </div>
              <div className="col-6">
                <LabelledInput title="Longitude*" name="longitude" disabled value={building.longitude} />
              </div>
            </div>
          </form> */}
          </div>

          <div className=" row-wise space-between px-4">
            <SecondaryButton
              className="mb-2 ms-1 mt-2 w-100"
              label={"Cancel"}
              clicked={() => { }}
            // disable={isSaveDisabled}
            />

            <PrimaryButton
              className="mb-2 ms-1 mt-2 w-100"
              label="Save"
              clicked={() => {
                // setConfirmAlert({alert :true, message : });
                startBuildingVenue()
              }}
              disable={isSaveDisabled}
            />
          </div>

        </div>
      </div>

      <Spinner show={loading} />

      <ConfirmAlert
        message={confirmAlert.message}
        primaryText={"Yes"}
        secondaryText={"No"}
        secondaryAction={() => {
          setConfirmAlert({alert : false, message : ''});
          setAssetsInBuilding([]);
        }}
        visible={confirmAlert.alert}
        title={"Finish Building Creation"}
        primaryAction={() => {
          setConfirmAlert({alert : false, message : ''});
          createBuilding();
        }}
      />

      <ErrorAlert
        show={error.alert}
        onDismiss={() => {
          setError({ message: "", alert: false });
        }}
        message={error.message}
      />
      <SuccessAlert
        show={success.alert}
        onDismiss={() => {
          setSuccess({ message: "", alert: false });
        }}
        message={success.message}
      />
      <WarningAlert
        show={warning.alert}
        onDismiss={() => {
          setWarning({ message: "", alert: false });
        }}
        message={warning.message}
      />
    </div>
  );
};
