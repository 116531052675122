import LeafBlue from '../assets/leaf_blue.png';
import LeafGreen from '../assets/leaf_green.png';
import LeafOrange from '../assets/leaf_orange.png';
import LeafPurple from '../assets/leaf_purple.png';
import LeafYellow from '../assets/leaf_yellow.png';
import SMBBlue from '../assets/smb_blue.png';
import SMBGreen from '../assets/smb_green.png';
import SMBOrange from '../assets/smb_orange.png';
import SMBPurple from '../assets/smb_purple.png';
import SMBYellow from '../assets/smb_yellow.png';
import Preloaded from '../assets/leaf_preloaded.png';
import LeafRed from "../assets/ap_physcial_decom.png";
import SMBRed from "../assets/omni_physcial_decomm.png";
import LeafRedDecom from "../assets/ap_decomm.png";
import SMBRedDecom from "../assets/omni_decomm.png"
import CPE from "../assets/CPE.svg"

export const SMB_ICONS: any = {
  SAS: SMBGreen,
  OpenWifi: SMBGreen,
  Cloud: SMBOrange,
  'Field Tech': SMBBlue,
  'RMA in progress': SMBBlue,
  'RMA Requested': SMBPurple,
  "Decommission Requested": SMBYellow,
  "Physically Decommissioned": SMBRed,
  "Decommissioned": SMBRedDecom,
  Preloaded: SMBGreen,
  'Field Tech White': CPE,
};

export const PRELOADED_ICONS: any = {
  Preloaded: Preloaded,
};

export const LEAF_ICONS: any = {
  SAS: LeafGreen,
  Cloud: LeafOrange,
  'Field Tech': LeafBlue,
  'RMA in progress': LeafBlue,
  'RMA Requested': LeafPurple,
  "Decommission Requested": LeafYellow,
  "Physically Decommissioned": LeafRed,
  "Decommissioned": LeafRedDecom
};
