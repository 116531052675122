import React, { useState } from 'react';
import './ImportDataModal.css';
import Modal from './Modal';
import UploadModal from './UploadModal';
import UploadErrorModal from './UploadErrorModal';
import UploadSuccessModal from './UploadSuccessModal';
import Spinner from './Spinner';
import { ApiHandler } from '../services/apiHandler';
import ErrorAlert from './ErrorAlert';


interface ImportDataModalProps {
  show: boolean;
  close: () => void;
}

export interface ValidationResults {
  total_uploaded: number;
  total_valid: number;
  issue_counts: {
    issue: string;
    count: number;
  }[];
  validated_file: string;
}

interface CreateSuccess {
  total_uploaded: number;
}

const ImportDataModal: React.FC<ImportDataModalProps> = ({ show, close }) => {
  const [stage, setStage] = useState<'upload' | 'error' | 'success'>('upload');
  const [validationResults, setValidationResults] = useState<ValidationResults>({} as ValidationResults);
  const [createSuccess, setCreateSuccess] = useState<CreateSuccess>({} as CreateSuccess);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ message: '', alert: false });
  const [uploadAPI, setUploadAPI] = useState<'validateAndCreateUploadFile'|'validateAndCreateDecommUploadFile'>('validateAndCreateUploadFile');

  // Function to receive the templateFileName from the child component
  const uploadAPIChange = (apiname: 'validateAndCreateUploadFile'|'validateAndCreateDecommUploadFile') => {
    setUploadAPI(apiname);
  };

  const closeGracefully = () => {
    setStage('upload');
    setLoading(false);
    setError({ message: '', alert: false });
    setValidationResults({} as ValidationResults);
    close();
  };

  const catchApiError = (error: any) => {
    setLoading(false);
    if (error.doLogout === true) {
      setError({ message: 'Session Timed Out', alert: true });
    } else {
      setError({ message: error.data.message, alert: true });
    }
  };
  

  const uploadFile = (file: any) => {
    setLoading(true);
    let formData = new FormData();
    formData.append('file', file);
    console.log(uploadAPI)
    ApiHandler({ apiName: uploadAPI, body: formData, params: {} })
      .then((response: any) => {
        setLoading(false);
        setCreateSuccess(response.data as CreateSuccess);
        setStage('success');
      })
      .catch((error: any) => {
        if (error?.data?.message) catchApiError(error);
        else {
          setLoading(false);
          setValidationResults(error?.data as ValidationResults);
          setStage('error');
        }
      });
  };

  return (
    <>
      <UploadModal
        show={show && stage === 'upload'}
        close={closeGracefully}
        uploadAPI={uploadAPIChange}
        onUpload={(files: any) => {
          uploadFile(files[0]);
        }}
      />
      <UploadErrorModal
        show={show && stage === 'error'}
        close={closeGracefully}
        validationResults={validationResults}
        onBrowse={() => {
          setStage('upload');
          setValidationResults({} as ValidationResults);
        }}
        onDownload={() => {
          window.open(validationResults.validated_file, '_blank');
        }}
      />
      <UploadSuccessModal show={show && stage === 'success'} close={closeGracefully} totalCount={createSuccess.total_uploaded ?? 0} />
      <Spinner show={loading} />
      <ErrorAlert
        show={error.alert}
        onDismiss={() => {
          setError({ message: '', alert: false });
        }}
        message={error.message}
      />
    </>
  );
};

export default ImportDataModal;
