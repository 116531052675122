let placeHolderVenueId = '6774c205-9eda-48f2-b1f8-33d09a9c87e1';
let placeHolderBuildingId = 'c7bf628b-a198-48d9-8516-4c060e67fa4b';
let placeHolderFloorId = 'b67309b3-9f20-44f3-91c6-15ff1272979b';

export function convertLocalToDesigner(json: any): any {
  const { venue, building, floor } = json;
  placeHolderVenueId = intToUuidFormat(venue.id);
  placeHolderBuildingId = intToUuidFormat(building.id);
  placeHolderFloorId = intToUuidFormat(parseInt(`${venue.id}${building.id}`));

  // Create venue feature
  const venueFeature = {
    id: placeHolderVenueId, // Placeholder
    type: 'Feature',
    properties: {
      drawMode: 'polygon',
      category: 'venue',
      stroke: '#0166FF',
      'stroke-opacity': 1,
      'fill-opacity': 0.6,
      fill: '#0166FF',
      'stroke-width': 1,
      'stroke-dasharray': [1, 0],
      parentType: 'venue',
      name: venue.name,
      center: [venue.location_point.lng, venue.location_point.lat],
      venueType: 'Stadium', // Placeholder
      address: {
        country: venue.country,
      },
    },
    geometry: {
      coordinates: [venue.polygon_points.map((point: any) => [point.lng, point.lat])],
      type: 'Polygon',
    },
  };

  // Create building feature
  const buildingFeature = {
    id: placeHolderBuildingId, // Placeholder
    type: 'Feature',
    properties: {
      category: 'building',
      name: building.name,
      center: [building.location.lng, building.location.lat],
      address: {},
      numLevels: building.total_floors,
      heightUnit: 'm', // Placeholder
      elevationUnit: 'm', // Placeholder
      parentId: venueFeature.id,
    },
    geometry: {
      type: 'Point',
      coordinates: [building.location.lng, building.location.lat],
    },
  };

  // Create floor feature
  const floorFeature = {
    id: placeHolderFloorId, // Placeholder
    type: 'Feature',
    properties: {
      category: 'level',
      name: `Floor ${floor.floor_number}`,
      levelNumber: parseInt(floor.floor_number) ? parseInt(floor.floor_number) : '',
      heightUnit: 'm', // Placeholder
      elevation: floor?.ceiling_from_ground ? parseInt(floor.ceiling_from_ground) : '', // Placeholder
      elevationUnit: 'm', // Placeholder
      azimuth: 0, // Placeholder
      parentId: buildingFeature.id,
    },
    geometry: {
      type: 'Point',
      coordinates: [building.location.lng, building.location.lat], // Placeholder
    },
  };

  return [
    {
      type: 'FeatureCollection',
      features: [venueFeature, buildingFeature, floorFeature],
    },
    {
      venueId: placeHolderVenueId,
      buildingId: placeHolderBuildingId,
      floorId: placeHolderFloorId,
    },
  ];
}

export function parseDesignerResult(designerResult: any) {
  const roomObject = designerResult.features.find(
    (feature: any) => feature.properties?.category === 'room' && feature.properties?.parentId === placeHolderFloorId
  );

  const imageObject = designerResult.features.find(
    (feature: any) => feature.properties?.assetType === 'IMAGE' && feature.properties?.parentId === placeHolderFloorId
  );

  // google map specific things
  const floorImageData = imageObject
    ? {
        image: imageObject?.properties?.['ground-overlay'],
        coords: imageObject?.properties?.['ground-overlay-box'].map((coord: any) => {
          return {
            lat: coord[1],
            lng: coord[0],
          };
        }),
      }
    : {};

  const floorFootPrintData = roomObject
    ? {
        cords: roomObject?.geometry?.geometries?.map(({ coordinates }: any) => {
          return coordinates[0].map((coord: any) => {
            return {
              lat: coord[1],
              lng: coord[0],
            };
          });
        }),
      }
    : {};

  const designerGeoJson = {
    type: 'FeatureCollection',
    features: [{ ...roomObject }],
  };

  console.log('Room Object:', roomObject);
  console.log('Image Object:', imageObject);
  console.log('Floor Image Data:', floorImageData);
  console.log('Floor Footprint Data:', floorFootPrintData);

  if (!roomObject || !imageObject) {
    return {};
  }

  return {
    designerFloorFootprint: roomObject,
    designerFloorImage: imageObject,
    floorImageData,
    floorFootPrintData,
    designerGeoJson,
  };
}

function intToUuidFormat(number: number) {
  const numStr = number.toString();

  const paddedStr = numStr.padEnd(32, '1');

  return (
    paddedStr.slice(0, 8) +
    '-' +
    paddedStr.slice(8, 12) +
    '-' +
    paddedStr.slice(12, 16) +
    '-' +
    paddedStr.slice(16, 20) +
    '-' +
    paddedStr.slice(20, 32)
  );
}
