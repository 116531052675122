import React, { useEffect, useState } from "react";
import { IVenueDetailsProps } from "./IVenueDetailsProps";
import "./_venuedetails.css";
import { ApiHandler } from "../../services/apiHandler";
import ConfirmAlert from "../confirmAlert";
import ErrorAlert from "../ErrorAlert";
import SuccessAlert from "../SuccessAlert";
import WarningAlert from "../WarningAlert";
import Spinner from "../Spinner";
import { useNavigate } from "react-router-dom";
import { getTechnology } from "../../services/helper";

export const VenueDetails: React.FC<IVenueDetailsProps> = (props) => {
  let navigate = useNavigate();
  const [venueDetails, setVenueDetails] = useState<any>();
  const [buildings, setBuildings] = useState<any[]>();
  const [loading, setLoading] = useState(false);
  const [assets, setAssets]: any = useState();
  const [totalAssets, setTotalAssets]: any = useState();
  const [outdoorAssets, setOutdoorAssets]: any = useState();
  const [totalAssetStatus, setTotalAssetStatus]: any = useState({ fieldTech: 0, cloud: 0, sas: 0 });
  const [outdoorAssetStatus, setOutdoorAssetStatus]: any = useState({ fieldTech: 0, cloud: 0, sas: 0 });
  const [error, setError] = useState({
    message: "",
    alert: false,
  });
  const [warning, setWarning] = useState({
    message: "",
    alert: false,
  });
  const [success, setSuccess] = useState({
    message: "",
    alert: false,
  });
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState({ alert: false, message: "" });

  useEffect(() => {
    props.setBuildingId("");
  }, []);

  useEffect(() => {
    getVenueDetails();
    listBuildings();
  }, [props.venueId]);

  const getVenueDetails = async () => {
    ApiHandler({ apiName: "getVenue", body: {}, params: { venue_id: props.venueId } })
      .then((res) => {
        setVenueDetails(res.data);
        setAssets(res.data.asset);
        setTotalAssets(res.data.total_assets);
        setOutdoorAssets(res.data.outdoor_assets);
        setTotalAssetStatus({
          fieldTech: res.data.total_field_tech,
          cloud: res.data.total_cloud,
          sas: res.data.total_sas,
        });
        setOutdoorAssetStatus({
          fieldTech: res.data.outdoor_field_tech,
          cloud: res.data.outdoor_cloud,
          sas: res.data.outdoor_sas,
        });
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error?.data?.message, alert: true });
        }
      });
  };

  const deleteVenue = async () => {
    setLoading(true);
    ApiHandler({ apiName: "deleteVenue", body: {}, params: { venue_id: props.venueId } })
      .then((res) => {
        setLoading(false);
        setSuccess({ message: "Venue Deleted Successfully", alert: true });
        setTimeout(() => {
          props.navigateTo("home");
        }, 1500);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const listBuildings = () => {
    setLoading(true);
    ApiHandler({ apiName: "listBuildings", body: {}, params: { venue_id: props.venueId } })
      .then((res) => {
        setLoading(false);
        setBuildings(res.data);
        props.setBuildingsForVenue(res.data);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error?.data?.message, alert: true });
        }
      });
  };

  const goToBuilding = (building: any) => {
    props.setBuildingId(building.id);
    props.navigateTo("buildingDetails");
  };

  const checkAssetsInVenue = () => {
    if (buildings?.length !== 0) {
      setWarning({ message: "Please delete buildings before deleting venue", alert: true });
    } else {
      let deleteMessage: string = "<span>Following assets are present.</span><br/><br/><ul>";
      assets?.forEach((asset: any) => {
        deleteMessage += "<li>" + asset.tracking_id + "</li>";
      });
      deleteMessage +=
        "</ul><i>On confirming the deletion, the assets will <b>automatically be dissociated from the Venue</b>. <br/><br/> Do you want to proceed?</i>";
      setDeleteConfirmationModal(
        assets?.length
          ? { alert: true, message: deleteMessage }
          : { alert: true, message: "Are you sure you want to delete the Venue" }
      );
    }
  };

  const removeAssetsVenue = () => {
    setLoading(true);
    const requestBody: any = { asset_ids: [] };
    assets.forEach((asset: any) => {
      requestBody.asset_ids.push(asset.id);
    });
    ApiHandler({ apiName: "removeAssetFromVenue", body: requestBody, params: {} })
      .then((response: any) => {
        setLoading(false);
        deleteVenue();
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: "Session Timed Out", alert: true });
          setTimeout(() => navigate("/login", { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  return (
    <div className="h-100 overflow-auto">
      <div className="app-card position-relative app-card-size right px-4 py-3">
        <div className="row-wise vertical-center justify-content-between">
          <div className="row-wise vertical-center">
            <div
              className="icon-background-active"
              onClick={() => {
                props.navigateTo("home");
              }}
            >
              <span className="material-icons-round icon-active">arrow_back</span>
            </div>

            <label className="venue-name ms-3">{venueDetails?.name}</label>
          </div>

          {/* Edit and delete options */}
          <div className="btn-group dropstart">
            <span
              title="Options"
              className="material-icons-round user-select-none cursor-pointer"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              more_vert
            </span>
            <ul className="dropdown-menu">
              <li>
                <button
                  className="dropdown-item"
                  type="button"
                  onClick={() => {
                    props.navigateTo("editVenue");
                  }}
                >
                  Edit
                </button>
              </li>
              <li>
                <button
                  className="dropdown-item color-red"
                  type="button"
                  onClick={() => {
                    checkAssetsInVenue();
                  }}
                >
                  Delete
                </button>
              </li>
            </ul>
          </div>
        </div>

        <hr style={{ borderTop: "5px solid #e5e5e5" }}></hr>

        <div className="col-wise overflow-auto">
          <span className="entity-title mb-2">Description</span>
          <span className="entity-label mb-3">{venueDetails?.description}</span>

          <span className="entity-title mb-2">Address</span>
          <span className="entity-label mb-3">{venueDetails?.address}</span>

          <span className="entity-title mb-2">
            {`${getTechnology() === "wifi_6e" ? "WIFI" : "5G"} Devices`}{" "}
          </span>

          <div className="row-wise">
            <div className="w-50 me-1 result-box col-wise">
              <span className="result-title">Total in Venue</span>
              <span className="result-label">{totalAssets}</span>
              <div className="row-wise align-items-center">
                <div title="Field Tech" className="field-tech-circle me-1"></div>
                <span title="Field Tech" className="me-2 result-title">
                  {totalAssetStatus.fieldTech}
                </span>

                <div title="Cloud" className="cloud-circle me-1"></div>
                <span title="Cloud" className="me-2 result-title">
                  {totalAssetStatus.cloud}
                </span>

                {getTechnology() !== "wifi_6e" ? (
                  <div className="row-wise align-items-center">
                    <div title="SAS" className="sas-circle me-1"></div>
                    <span title="SAS" className="me-2 result-title">
                      {totalAssetStatus.sas}
                    </span>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="w-50 ms-1 result-box col-wise">
              <span className="result-title">Outdoor</span>
              <span className="result-label">{outdoorAssets}</span>
              <div className="row-wise align-items-center">
                <div title="Field Tech" className="field-tech-circle me-1"></div>
                <span title="Field Tech" className="me-2 result-title">
                  {outdoorAssetStatus.fieldTech}
                </span>

                <div title="Cloud" className="cloud-circle me-1"></div>
                <span title="Cloud" className="me-2 result-title">
                  {outdoorAssetStatus.cloud}
                </span>

                {getTechnology() !== "wifi_6e" ? (
                  <div className="row-wise align-items-center">
                    <div title="SAS" className="sas-circle me-1"></div>
                    <span title="SAS" className="me-2 result-title">
                      {outdoorAssetStatus.sas}
                    </span>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>

          <div className="row-wise justify-content-between mt-3 mb-3" style={{ alignItems: "center" }}>
            <label className="building-title">BUILDINGS</label>

            <span
              title="Add New Building"
              className="material-icons-round add-icon"
              onClick={() => {
                props.navigateTo("addBuilding");
                props.setBuildingNavigation("venueDetails")

              }}
            >
              add_circle_outline
            </span>
          </div>

          <div className="overflow-auto">
            {buildings?.map((building: any) => {
              return (
                <div
                  className="building-card"
                  onClick={() => {
                    goToBuilding(building);
                  }}
                  key={building.id}
                >
                  {/* Image */}
                  {building.image && building.image !== "" ? (
                    <img src={building.image} className=" building-image-box image" />
                  ) : (
                    <div className="building-image-box">
                      <span className="material-icons-outlined icon">apartment</span>
                    </div>
                  )}
                  {/* Details */}
                  <div className="building-details ms-3 w-100">
                    <div className="row-wise vertical-center justify-content-between w-100 mb-2">
                      <label className="name">{building.name}</label>
                      <span className="material-icons-outlined icon">navigate_next</span>
                    </div>

                    <label className="description mb-2 h-100">{building.description}</label>
                    <label className="total-floors">{`${building.total_assets} ${
                      getTechnology() === "wifi_6e" ? "WIFI" : "5G"
                    } devices`}</label>
                    <label className="total-floors">{building.total_floors} Floors</label>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>

      <Spinner show={loading} />

      {/* Delete confirmation modal */}
      <ConfirmAlert
        message={deleteConfirmationModal.message}
        primaryText={"Yes"}
        secondaryText={"No"}
        secondaryAction={() => {
          setDeleteConfirmationModal({ alert: false, message: "" });
        }}
        visible={deleteConfirmationModal.alert}
        title={"Delete Venue"}
        primaryAction={() => {
          setDeleteConfirmationModal({ alert: false, message: "" });
          assets?.length !== 0 ? removeAssetsVenue() : deleteVenue();
        }}
      />

      <ErrorAlert
        show={error.alert}
        onDismiss={() => {
          setError({ message: "", alert: false });
        }}
        message={error.message}
      />
      <SuccessAlert
        show={success.alert}
        onDismiss={() => {
          setSuccess({ message: "", alert: false });
        }}
        message={success.message}
      />
      <WarningAlert
        show={warning.alert}
        onDismiss={() => {
          setWarning({ message: "", alert: false });
        }}
        message={warning.message}
      />
    </div>
  );
};
