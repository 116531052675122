import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AerialIcon from '../assets/aerial.svg';
import RoeIcon from '../assets/roe.svg';
import sectorIcon from '../assets/sectorIcon.svg';
import sectorIconSelected from '../assets/sectorIconSelected.svg';
import { DEFAULT_SECTOR } from '../constants/AddAssetDefault';
import IAssetImageObj from '../constants/assetImageObject';
import { CBSDMountingTypes } from '../constants/GlobalConstants';
import MOUNTING_TYPES from '../constants/mountingTypes';
import { AddSectorProps } from '../interfaces/AddAssetProps';
import AssetProps, { AssetObj } from '../interfaces/AssetProps';
import { ApiHandler } from '../services/apiHandler';
import { exists, getOrganisationId, getRole } from '../services/helper';
import './Asset.css';
import AssetImagesModal from './AssetImagesModal/AssetImagesModal';
import ConfirmAlert from './confirmAlert';
import ErrorAlert from './ErrorAlert';
import LabelledInput from './LabelledInput';
import LabelLessDropdown from './LabelLessDropdown';
import PrimaryButton from './PrimaryButton';
import SecondaryButton from './SecondaryButton';
import Spinner from './Spinner';
import SuccessAlert from './SuccessAlert';
import WarningAlert from './WarningAlert';

interface EditAssetProps {
  goBack: any;
  action: string;
  azimuthActivity: any;
  position: { lat: number; lng: number };
  assetDetails: AssetProps | undefined;
  rmaSerialNumber: string;
  setBuildingId: (buildingId: any) => void;
  setFloorId: (floorId: any) => void;
  setFloorGeoJSONUrl: (url: any) => void;
  unsetFloorGeoJSON: () => void;
  venuesOnMap: any[];
  setVenueId: (venueId: any) => void;
  goToProfile: () => void;
}

const EditAsset: React.FC<EditAssetProps> = ({
  rmaSerialNumber,
  action,
  goBack,
  azimuthActivity,
  assetDetails,
  position,
  setBuildingId,
  setFloorId,
  setFloorGeoJSONUrl,
  unsetFloorGeoJSON,
  venuesOnMap,
  setVenueId,
  goToProfile,
}) => {
  const azimuthInterval = useRef<NodeJS.Timer>();
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    message: '',
    alert: false,
  });
  const [warning, setWarning] = useState({
    message: '',
    alert: false,
  });
  const [success, setSuccess] = useState({
    message: '',
    alert: false,
  });
  const [azimuth, setAzimuth] = useState<string[]>([]);
  const [azimuthAngle, setAzimuthAngle] = useState(0);
  const [isAzimuthActive, activateAzimuth] = useState<boolean>(false);
  const [edittedAsset, setEdittedAsset] = useState<AssetObj | undefined>(assetDetails?.asset);
  const [oldSerialNumber, setOldSerialNumber] = useState('');
  const [directionality, setDirectionality] = useState<'Omni' | 'Directional' | ''>('');
  const [vendorType, setVendorType] = useState<'Airspan' | 'Nokia' | ''>('');
  const [selectedBuilding, setSelectedBuilding] = useState<any>(null);
  const [availableBuildings, setAvailableBuildings] = useState<any[]>([]);
  const [availableFloors, setAvailableFloors] = useState<any[]>([]);
  const [selectedFloor, setSelectedFloor] = useState<any>(null);
  const [associatedVenue, setAssociatedVenue] = useState<any>(null);
  const [assetOnVenueAlert, setAssetOnVenueAlert] = useState(false);
  const [showImageModal, setShowImageModal] = useState(false);
  const [vendorNotSelected, showVendorNotSelected] = useState(false);
  const [assetImages, setAssetImages] = useState<IAssetImageObj[]>([
    {
      type: 'RADIO_LABEL',
      path: '',
      label: 'Radio Label',
      uploaded: false,
      image: null,
      edited: false,
      latitude: 0,
      longitude: 0,
    },
    {
      type: 'OPERATIONAL_RF_SIGNAL_LEVEL',
      path: '',
      label: 'Operational RF Signal Level',
      uploaded: false,
      image: null,
      edited: false,
      latitude: 0,
      longitude: 0,
    },
    {
      type: 'OPERATIONAL_VOLTAGE_LEVEL',
      path: '',
      label: 'Operational Voltage Level',
      uploaded: false,
      image: null,
      edited: false,
      latitude: 0,
      longitude: 0,
    },
    {
      type: 'COAX_CABLE_CONNECTION_LIGHTS',
      path: '',
      label: 'Coax Cable Connection and Lights',
      uploaded: false,
      image: null,
      edited: false,
      latitude: 0,
      longitude: 0,
    },
    {
      type: 'DIRECTIONAL_COUPLER_RADIO_CONNECTION',
      path: '',
      label: 'Connection from Directional Coupler to Radio',
      uploaded: false,
      image: null,
      edited: false,
      latitude: 0,
      longitude: 0,
    },
    {
      type: 'INSTALLATION_STREET_VIEW',
      path: '',
      label: 'Installation Street View',
      uploaded: false,
      image: null,
      edited: false,
      latitude: 0,
      longitude: 0,
    },
    {
      type: 'MISCELLANEOUS_1',
      path: '',
      label: 'Select a Photo',
      uploaded: false,
      image: null,
      edited: false,
      latitude: 0,
      longitude: 0,
    },
    {
      type: 'MISCELLANEOUS_2',
      path: '',
      label: 'Select a Photo',
      uploaded: false,
      image: null,
      edited: false,
      latitude: 0,
      longitude: 0,
    },
    {
      type: 'MISCELLANEOUS_3',
      path: '',
      label: 'Select a Photo',
      uploaded: false,
      image: null,
      edited: false,
      latitude: 0,
      longitude: 0,
    },
    {
      type: 'MISCELLANEOUS_4',
      path: '',
      label: 'Select a Photo',
      uploaded: false,
      image: null,
      edited: false,
      latitude: 0,
      longitude: 0,
    },
  ]);

  useEffect(() => {
    assetDetails?.sectors.map((sector: any) => {
      setAzimuth((prevValues) => {
        return [...prevValues, sector.azimuth.toString()];
      });
    });
    setDirectionality(assetDetails?.asset.directionality ?? '');
    setVendorType(assetDetails?.asset.vendor_type ?? '');
    if (action === 'rma') {
      setOldSerialNumber(assetDetails?.asset.serial_number || '');
      setEdittedAsset((prevValues: any) => {
        return { ...prevValues, ['serial_number']: rmaSerialNumber };
      });
    }
    return () => {
      stopAzimuthRotation();
      cancelAzimuth();
      setFloorId('');
      setBuildingId('');
    };
  }, []);

  useEffect(() => {
    if (position.lat != assetDetails?.asset?.latitude && position.lng != assetDetails?.asset?.longitude) validateIsAssetOnVenue();
  }, [position]);

  useEffect(() => {
    setPreloadedImages();

    if (assetDetails?.asset.venue_id !== null) {
      listBuildings(assetDetails?.asset.venue_id);
      setAssociatedVenue({
        id: assetDetails?.asset.venue_id,
        name: assetDetails?.asset.venue_name,
      });
    }
    if (assetDetails?.asset?.status === 'Preloaded') {
      getDefaultVendorsInfo(assetDetails?.asset?.mounting_type ?? '');
    }
  }, [assetDetails]);

  useEffect(() => {
    setAssetOnVenueAlert(associatedVenue !== null);
  }, [associatedVenue]);

  useEffect(() => {
    if (edittedAsset?.status === 'Preloaded') onChangeDirectionality(directionality, vendorType);
  }, [directionality, vendorType]);

  const setPreloadedImages = () => {
    let images: IAssetImageObj[] = [...assetImages];
    assetDetails?.asset?.images?.forEach((image: any) => {
      let index = images?.findIndex((img: any) => img.type === image.type);
      if (index !== -1) {
        images[index].path = image?.path;
        images[index].uploaded = true;
      }
    });
    setAssetImages(images);
  };

  const onChangeDirectionality = (_directionality: 'Directional' | 'Omni' | '', _vendorType: 'Airspan' | 'Nokia' | '') => {
    if (['Omni', ''].includes(_directionality)) return;
    if (exists(edittedAsset?.mounting_type) && edittedAsset?.mounting_type === CBSDMountingTypes.AERIAL) {
      setAzimuth(['0', '180']);
      return;
    }
    if (exists(edittedAsset?.mounting_type) && edittedAsset?.mounting_type === CBSDMountingTypes.ROE) {
      setAzimuth(['0']);
      return;
    }
  };

  const getDefaultVendorsInfo = (mountingType: string) => {
    setLoading(true);
    const requestParams: any = {
      mountingtype: mountingType,
    };
    ApiHandler({
      apiName: 'getDefaultVendorsInfo',
      body: {},
      params: requestParams,
    })
      .then((response: any) => {
        setLoading(false);
        setDefaultVendor(response.data.vendor_id);
        setDirectionality(response.data.directionality);
        setVendorType(response.data.vendor_type);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: 'Session Timed Out', alert: true });
          setTimeout(() => navigate('/login', { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const setDefaultVendor = (vendorId: any) => {
    if (vendorId === null || vendorId === 0) {
      showVendorNotSelected(true);
      return;
    }
    setEdittedAsset((prevValues: any) => {
      return { ...prevValues, ['vendor_id']: vendorId };
    });
  };

  const validateIsAssetOnVenue = () => {
    let contains: boolean = false;
    let tempVenue: any = null;
    for (let venue of venuesOnMap) {
      let doesContain: boolean = window.google.maps.geometry.poly.containsLocation(
        new window.google.maps.LatLng(position.lat, position.lng),
        new window.google.maps.Polygon({ paths: venue?.polygon })
      );
      if (doesContain) {
        contains = doesContain;
        tempVenue = venue;
      }
    }
    if (contains) {
      setAssociatedVenue(tempVenue);
    } else {
      setAssociatedVenue(null);
      setSelectedBuilding(null);
      setSelectedFloor(null);
      showFloorPlan(null);
      setVenueId('');
      setBuildingId('');
      setFloorId('');
      setAvailableBuildings([]);
      setAvailableFloors([]);
    }
  };

  const initiateAssociationWithVenue = () => {
    setVenueId(associatedVenue?.id);
    listBuildings(associatedVenue?.id);
  };

  const listBuildings = (venueId: any) => {
    setLoading(true);
    ApiHandler({
      apiName: 'listBuildings',
      body: {},
      params: { venue_id: venueId },
    })
      .then((res) => {
        setLoading(false);
        setAvailableBuildings(res.data);
        if (assetDetails?.asset.building_id !== null) {
          listFloors(assetDetails?.asset.building_id);
          setSelectedBuilding(res.data.filter((builiding: any) => builiding.id === assetDetails?.asset.building_id)[0]);
          setBuildingId(assetDetails?.asset.building_id);
        }
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: 'Session Timed Out', alert: true });
          setTimeout(() => navigate('/login', { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const listFloors = (buildingId: any) => {
    setLoading(true);
    ApiHandler({
      apiName: 'listFloors',
      body: {},
      params: { building_id: buildingId },
    })
      .then((res) => {
        setLoading(false);
        setAvailableFloors(res.data);
        if (assetDetails?.asset.floor_id !== null) {
          setSelectedFloor(res.data.filter((floor: any) => floor.id === assetDetails?.asset.floor_id)[0]);
          setFloorId(assetDetails?.asset.floor_id);
          showFloorPlan(assetDetails?.asset.floor_id);
        }
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: 'Session Timed Out', alert: true });
          setTimeout(() => navigate('/login', { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const getSectors = () => {
    let sectorsHtml: any = [];
    let numSectors = 0;
    if (assetDetails?.asset.mounting_type === 'Aerial' && directionality === 'Directional') {
      numSectors = 2;
    } else if (assetDetails?.asset.mounting_type === 'ROE' && directionality === 'Directional') {
      numSectors = 1;
    }
    for (let count = 0; count < numSectors; count++) {
      const element = (
        <div className="col-12 row-wise">
          <div className="col-6 pe-1">
            <LabelledInput title="Sector ID" disabled value={(count + 1).toString()} />
          </div>
          <div className="col-6 ps-1">
            <LabelledInput title="Azimuth" disabled value={azimuth[count]} />
          </div>
        </div>
      );
      sectorsHtml.push(element);
    }
    return sectorsHtml;
  };

  const getSectorIcons = () => {
    let totalIcons: number = 6;
    let iconList: any[] = [];
    if (assetDetails?.asset.mounting_type === 'SMB' || directionality !== 'Directional') {
      return iconList;
    }
    for (let index = 0; index < totalIcons; index++) {
      if ((assetDetails?.asset.mounting_type === 'Aerial' && index < 2) || (assetDetails?.asset.mounting_type === 'ROE' && index < 1)) {
        const element = <img src={sectorIconSelected}></img>;
        iconList.push(element);
      } else {
        const element = <img src={sectorIcon}></img>;
        iconList.push(element);
      }
    }
    return iconList;
  };

  const validateRequest = () => {
    if (edittedAsset?.serial_number?.trim() === '') {
      setWarning({ alert: true, message: 'Valid Serial Number is required.' });
      return false;
    } else if (edittedAsset?.height === 0 || isNaN(Number(edittedAsset?.height))) {
      setWarning({ alert: true, message: 'Valid height is required.' });
      return false;
    }
    return true;
  };

  const sectorStatus = (status: any) => {
    let userRole: string = getRole()?.toLowerCase() || 'installer';
    if (!['installer', 'engineer'].includes(userRole) && (action === 'rma' || ['RMA Requested', 'RMA in progress'].includes(status)))
      return 'RMA Requested';
    else if (action === 'rma' || ['RMA Requested', 'RMA in progress'].includes(status)) return 'RMA in progress';
    return 'Field Tech';
  };

  const replaceAsset = (requestBody: any) => {
    requestBody['old_serial_number'] = oldSerialNumber;
    ApiHandler({
      apiName: 'replaceAsset',
      body: requestBody,
      params: { tracking_id: assetDetails?.asset.tracking_id },
    })
      .then((response: any) => {
        setLoading(false);
        goBack('home');
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: 'Session Timed Out', alert: true });
          setTimeout(() => navigate('/login', { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const addSectorToRequest = (sectorAzimuth: string, sectorID: number, haat: string): AddSectorProps => {
    let sector: any = DEFAULT_SECTOR;
    sector.sector_id = sectorID.toString();
    sector.tracking_id = edittedAsset?.tracking_id;
    sector.height = parseFloat((parseFloat(edittedAsset?.height?.toString() || '0') / 3.281).toFixed(2));
    sector.azimuth = parseFloat(sectorAzimuth);
    sector.haat = haat;
    return sector;
  };

  const UpdateAsset = () => {
    if (!validateRequest()) return;
    setLoading(true);
    const lat = parseFloat(position.lat.toFixed(6));
    const lng = parseFloat(position.lng.toFixed(6));
    calculateElevation(lat, lng)
      .then((response: any) => {
        let rcamsl = response?.data?.elevation + parseFloat((parseFloat(edittedAsset?.height?.toString() || '0') / 3.281).toFixed(2));
        calculateHaat(lat, lng, rcamsl)
          .then((response: any) => {
            let haat = response?.data?.haat?.toFixed(2);
            // Adding sectors to the asset
            assetDetails?.sectors.map((sector: any, index: any) => {
              delete sector.submitted_to_sas;
              delete sector.submitted_to_cloud;
              sector.height = parseFloat((parseFloat(edittedAsset?.height?.toString() || '0') / 3.281).toFixed(2)); // to convert the height in sector to meters
              sector.azimuth = parseFloat(azimuth[index].toString());
              sector.haat = haat;
              sector.status = sectorStatus(sector.status);
            });

            let requestBody: any = edittedAsset;

            let uploadedImages: any = [];
            assetImages?.forEach((image: IAssetImageObj) => {
              if (image.edited) {
                uploadedImages.push({ type: image.type, path: image.path, latitude: image.latitude, longitude: image.longitude });
              }
            });

            let sectorList: AddSectorProps[] = [];
            if (assetDetails?.asset?.status === 'Preloaded') {
              if ([CBSDMountingTypes.AERIAL, CBSDMountingTypes.ROE].includes(edittedAsset?.mounting_type ?? '') && directionality !== 'Omni') {
                azimuth.forEach((value: any, index: any) => {
                  const sector: AddSectorProps = addSectorToRequest(value, index + 1, haat);
                  const finalSector: any = { ...sector };
                  sectorList.push(finalSector);
                });
              }
              // Adding a sector to the SMB to store values in DB but will not be treated as a sector in the functionalities
              if (edittedAsset?.mounting_type === CBSDMountingTypes.SMB || directionality === 'Omni') {
                const sector: AddSectorProps = addSectorToRequest('0', 1, haat);
                const finalSector: any = { ...sector };
                sectorList.push(finalSector);
              }
            }

            requestBody.latitude = lat;
            requestBody.longitude = lng;
            requestBody.images = uploadedImages;
            requestBody.height = parseFloat(requestBody.height.toString());
            requestBody.status = sectorStatus(requestBody.status);
            requestBody.sectors = assetDetails?.asset?.status === 'Preloaded' ? sectorList : assetDetails?.sectors;
            requestBody.venue_id = associatedVenue ? associatedVenue.id : null;
            requestBody.building_id = selectedBuilding ? selectedBuilding.id : null;
            requestBody.floor_id = selectedFloor ? selectedFloor.id : null;
            requestBody.deployment_location = associatedVenue ? 'indoor' : 'outdoor';

            if (action === 'rma') {
              replaceAsset(requestBody);
            } else {
              ApiHandler({
                apiName: 'updateAsset',
                body: requestBody,
                params: { tracking_id: assetDetails?.asset.tracking_id },
              })
                .then((response: any) => {
                  setLoading(false);
                  goBack('home');
                })
                .catch((error: any) => {
                  setLoading(false);
                  if (error.doLogout === true) {
                    setError({ message: 'Session Timed Out', alert: true });
                    setTimeout(() => navigate('/login', { replace: true }), 2000);
                  } else {
                    setError({ message: error.data.message, alert: true });
                  }
                });
            }
          })
          .catch((error: any) => {
            setLoading(false);
            setError({
              message: error.response.data.features[0].properties.statusMessage,
              alert: true,
            });
          });
      })
      .catch((error: any) => {
        setLoading(false);
        setError({
          message: error.response.data.features[0].properties.statusMessage,
          alert: true,
        });
      });
  };

  const calculateElevation = (lat: number, lng: number) => {
    let requestParams = {
      lat: lat,
      lon: lng,
      src: 'ned_1',
      unit: 'm',
    };
    // return axios.get('https://geo.fcc.gov/api/contours/elevation.json', {
    //   params: requestParams,
    // });
    return ApiHandler({ apiName: 'fetchElevation', body: requestParams, params: {} });
  };

  const calculateHaat = (lat: number, lng: number, rcamsl: number) => {
    let requestParams = {
      lat: lat,
      lon: lng,
      nradial: 8,
      rcamsl: rcamsl,
      src: 'ned_1',
      unit: 'm',
    };
    // return axios.get('https://geo.fcc.gov/api/contours/haat.json', {
    //   params: requestParams,
    // });
    return ApiHandler({ apiName: 'fetchHaat', body: requestParams, params: {} });
  };

  const handleInputChange = (e: any) => {
    setEdittedAsset((prevValues: any) => {
      return { ...prevValues, [e.target.name]: e.target.value };
    });
  };

  const startIncreaseAzimuth = () => {
    setAzimuthAngle((azimuthAngle) => (azimuthAngle == 359 ? 0 : azimuthAngle + 1));
    azimuthInterval.current = setInterval(() => {
      setAzimuthAngle((azimuthAngle) => (azimuthAngle == 359 ? 0 : azimuthAngle + 1));
    }, 100);
  };

  const startDecreaseAzimuth = () => {
    setAzimuthAngle((azimuthAngle) => (azimuthAngle == 0 ? 359 : azimuthAngle - 1));
    azimuthInterval.current = setInterval(() => {
      setAzimuthAngle((azimuthAngle) => (azimuthAngle == 0 ? 359 : azimuthAngle - 1));
    }, 100);
  };

  const stopAzimuthRotation = () => {
    clearInterval(azimuthInterval.current);
  };

  const confirmAzimuth = () => {
    if (assetDetails?.asset.mounting_type === 'ROE') {
      setAzimuth([azimuthAngle.toString()]);
    } else if (assetDetails?.asset.mounting_type === 'Aerial') {
      let s1 = azimuthAngle + 225;
      let s2 = azimuthAngle + 45;
      if (s1 >= 360) s1 = s1 - 360;
      if (s2 >= 360) s2 = s2 - 360;
      setAzimuth([s1.toString(), s2.toString()]);
    }
    cancelAzimuth();
  };

  const cancelAzimuth = () => {
    azimuthActivity(false);
    activateAzimuth(false);
  };

  const showFloorPlan = (floorId: any) => {
    unsetFloorGeoJSON();
    if (floorId === null) return;
    setLoading(true);
    ApiHandler({
      apiName: 'getFloorGeoJson',
      body: {},
      params: { floor_id: floorId },
    })
      .then((res) => {
        setLoading(false);
        setFloorGeoJSONUrl(res.data.url);
      })
      .catch((error: any) => {
        setLoading(false);
        if (error.doLogout === true) {
          setError({ message: 'Session Timed Out', alert: true });
          setTimeout(() => navigate('/login', { replace: true }), 2000);
        } else {
          setError({ message: error.data.message, alert: true });
        }
      });
  };

  const handleAlertForImages = (alertType: 'error' | 'warning' | 'success', message: string) => {
    if (alertType === 'error') {
      setError({ message: message, alert: true });
    } else if (alertType === 'warning') {
      setWarning({ message: message, alert: true });
    } else if (alertType === 'success') {
      setSuccess({ message: message, alert: true });
    }
  };

  const initiateImageUpload = () => {
    if (edittedAsset?.tracking_id?.trim() === '') {
      setWarning({
        alert: true,
        message:
          getOrganisationId() !== 1 || vendorType !== 'Nokia' ? 'Tracking ID is required to upload images.' : 'UID is required to upload images.',
      });
      return;
    }
    setShowImageModal(true);
  };

  const getDeviceTitle: any = {
    wifi_6e: 'WIFI',
    cbrs_5g: 'CBSD',
    cpe_cbsd: 'CPE',
    cpe_wifi: 'CPE',
    '': 'ASSET',
  };

  const getNameTitle: any = {
    wifi_6e: 'WIFI Name',
    cbrs_5g: 'CBRS Node Name',
    cpe_cbsd: 'CPE Name',
    cpe_wifi: 'CPE Name',
    '': 'Asset Name',
  };

  return (
    <div className="h-100 overflow-auto">
      <div className="app-card position-relative app-card-size">
        <div className="row-wise vertical-center">
          <div
            className="icon-background-active"
            onClick={() => {
              goBack('home');
            }}>
            <span className="material-icons-round icon-active">arrow_back</span>
          </div>

          <span className="app-card-action-heading ms-3">{`EDITING ${getDeviceTitle[assetDetails?.asset?.technology_type ?? '']}`}</span>
        </div>

        <span className="asset-drop-info mt-2 mb-2 w-100">{`Click where you'd like to drop the ${
          getDeviceTitle[assetDetails?.asset?.technology_type ?? '']
        }`}</span>

        <form className="overflow-auto pe-3 ps-1 pb-1 mb-1 pt-2">
          {getOrganisationId() != 1 ? (
            <div className="p-2 mb-2 venue-association-box col-wise">
              {associatedVenue === null ? (
                <label className="mb-2 ms-2 association-info">Drop the location pin inside a venue to initiate association.</label>
              ) : (
                <span className="indoor-sub-heading mb-2 ms-2">
                  Venue: <span className="indoor-value">{associatedVenue.name}</span>
                </span>
              )}

              {/* Buildings dropdown */}
              <span className="indoor-sub-heading ms-2">Building</span>
              <div className="dropdown mb-2">
                <button className="w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {selectedBuilding ? selectedBuilding.name : 'Select Building (optional)'}
                </button>

                <ul className="dropdown-menu w-100">
                  {availableBuildings.map((building: any) => {
                    return (
                      <li key={building.id}>
                        <span
                          onClick={() => {
                            setSelectedBuilding(building);
                            setBuildingId(building.id);
                          }}
                          className="dropdown-item">
                          {building.name}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>

              {/* Floor dropdown */}
              <span className="indoor-sub-heading ms-2">Floor</span>
              <div className="dropdown">
                <button className="w-100" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                  {selectedFloor ? selectedFloor.floor_number : 'Select Floor (optional)'}
                </button>

                <ul className="dropdown-menu w-100">
                  {availableFloors.map((floor: any) => {
                    return (
                      <li key={floor?.id}>
                        <span
                          onClick={() => {
                            setSelectedFloor(floor);
                            setFloorId(floor?.id);
                            showFloorPlan(floor?.id);
                          }}
                          className="dropdown-item">
                          {floor?.floor_number}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          ) : (
            <></>
          )}
          <LabelledInput
            disabled
            title={getOrganisationId() !== 1 || vendorType !== 'Nokia' ? 'Tracking ID*' : 'UID*'}
            name="tracking_id"
            onChange={(e: any) => {
              handleInputChange(e);
            }}
            value={edittedAsset?.tracking_id ? edittedAsset.tracking_id : ''}
          />
          <LabelledInput
            title="gNB ID"
            name="gnodeb_id"
            onChange={(e: any) => {
              handleInputChange(e);
            }}
            value={edittedAsset?.gnodeb_id ? edittedAsset.gnodeb_id : ''}
          />
          <LabelledInput
            title={getNameTitle[assetDetails?.asset?.technology_type ?? '']}
            name="cbrs_node_name"
            onChange={(e: any) => {
              handleInputChange(e);
            }}
            value={edittedAsset?.cbrs_node_name ? edittedAsset.cbrs_node_name : ''}
          />
          <LabelledInput
            title="Site ID"
            name="site_id"
            onChange={(e: any) => {
              handleInputChange(e);
            }}
            value={edittedAsset?.site_id ? edittedAsset.site_id : ''}
          />
          {!['wifi_6e', 'cpe_cbsd', 'cpe_wifi', null, undefined].includes(assetDetails?.asset?.technology_type) ? (
            <LabelLessDropdown
              disabled
              className="mb-2"
              title="Mounting Type*"
              options={MOUNTING_TYPES}
              fullWidth
              value={edittedAsset?.mounting_type ? edittedAsset.mounting_type : ''}
            />
          ) : (
            <></>
          )}

          <div className="form-floating w-100 mb-2">
            <textarea
              rows={5}
              id="description"
              className="form-control textbox-box input-text h-auto"
              placeholder={'Description'}
              name="description"
              onChange={(e: any) => {
                handleInputChange(e);
              }}
              value={edittedAsset?.description ? edittedAsset?.description : ''}></textarea>
            <label className="input-label" htmlFor="description">
              Description
            </label>
          </div>

          <div
            className="image-box p-2 mb-2 col-wise cursor-pointer"
            onClick={() => {
              initiateImageUpload();
            }}>
            <span className="input-label mb-2">Images</span>
            <span>{`${assetImages?.filter((image: IAssetImageObj) => image.uploaded).length}/10 uploaded`}</span>
          </div>

          <LabelledInput title="Vendor Type" disabled value={directionality} />

          {assetDetails?.asset?.technology_type !== 'wifi_6e' && directionality ? (
            <div className="row-wise w-100 justify-content-evenly mb-2">{getSectorIcons().map((icon: any) => icon)}</div>
          ) : (
            <></>
          )}

          <LabelledInput
            title="Antenna Height (ft)*"
            type="number"
            name="height"
            onChange={(e: any) => {
              handleInputChange(e);
            }}
            value={edittedAsset?.height?.toString() ? edittedAsset?.height.toString() : ''}
          />
          <LabelledInput
            disabled={assetDetails?.asset.status === 'SAS' && action !== 'rma'}
            title="Serial #*"
            name="serial_number"
            onChange={(e: any) => {
              handleInputChange(e);
            }}
            value={edittedAsset?.serial_number ? edittedAsset?.serial_number : ''}
          />
          <LabelledInput
            title="MAC"
            name="device_mac"
            onChange={(e: any) => {
              handleInputChange(e);
            }}
            value={edittedAsset?.device_mac ? edittedAsset?.device_mac : ''}
          />
          <LabelledInput
            title="P/N"
            name="device_pn"
            onChange={(e: any) => {
              handleInputChange(e);
            }}
            value={edittedAsset?.device_pn ? edittedAsset?.device_pn : ''}
          />
          <LabelledInput
            title="Docsis Serial #"
            name="cm_serial_number"
            onChange={(e: any) => {
              handleInputChange(e);
            }}
            value={edittedAsset?.cm_serial_number ? edittedAsset?.cm_serial_number : ''}
          />
          <LabelledInput
            title="Docsis MAC"
            name="cm_mac"
            onChange={(e: any) => {
              handleInputChange(e);
            }}
            value={edittedAsset?.cm_mac ? edittedAsset?.cm_mac : ''}
          />
          <LabelledInput
            title="Docsis P/N"
            name="cm_pn"
            onChange={(e: any) => {
              handleInputChange(e);
            }}
            value={edittedAsset?.cm_pn ? edittedAsset?.cm_pn : ''}
          />
          <LabelledInput title="Latitude*" disabled value={position.lat.toFixed(6)} />
          <LabelledInput title="Longitude*" disabled value={position.lng.toFixed(6)} />
          {azimuth && getSectors().map((sector: any) => sector)}
          {assetDetails?.asset?.mounting_type !== 'SMB' && directionality === 'Directional' ? (
            <SecondaryButton
              className={'mb-2'}
              label="Set Azimuth Value"
              clicked={() => {
                azimuthActivity(true);
                activateAzimuth(true);
                setAzimuthAngle(0);
              }}
              disable={false}
            />
          ) : (
            <></>
          )}
        </form>
        <PrimaryButton
          className="mb-2 me-4 ms-1 mt-2"
          label={`Update ${getDeviceTitle[assetDetails?.asset?.technology_type ?? '']}`}
          clicked={UpdateAsset}
          disable={false}
        />
      </div>

      {/* Azimuth Image - rotates on button click */}
      <div className={'position-fixed azimuth-icon-position ' + (isAzimuthActive ? '' : 'd-none')}>
        <img
          src={assetDetails?.asset.mounting_type === 'Aerial' ? AerialIcon : RoeIcon}
          style={{ transform: 'rotate(' + azimuthAngle + 'deg)' }}></img>
      </div>

      {/* Azimuth action buttons */}
      <div
        className={'position-fixed ' + (isAzimuthActive ? '' : 'd-none')}
        style={{
          top: 'calc(50% + 22px)',
          right: '100px',
          transform: 'translate(0px, -50%)',
        }}>
        <div className="d-flex align-items-center col-wise">
          <div onClick={confirmAzimuth} className="center confirm-azimuth mb-4">
            <span className="material-icons-round">done</span>
          </div>

          <div className="col-wise">
            <div className="center plus-icon" onMouseDown={startIncreaseAzimuth} onMouseUp={stopAzimuthRotation} onMouseLeave={stopAzimuthRotation}>
              <span className="material-icons-round">add</span>
            </div>

            <div className="center azimuth-label">
              <label>{azimuthAngle} N</label>
            </div>

            <div className="center minus-icon" onMouseDown={startDecreaseAzimuth} onMouseUp={stopAzimuthRotation} onMouseLeave={stopAzimuthRotation}>
              <span className="material-icons-round">remove</span>
            </div>
          </div>

          <div onClick={cancelAzimuth} className="center reset-azimuth mt-4">
            <span className="material-icons-round">restart_alt</span>
          </div>
        </div>
      </div>

      <ConfirmAlert
        message={`You have moved the location pin in the <b>${associatedVenue?.name}</b> venue.`}
        note="Asset will be associated to this venue."
        primaryText={'OK'}
        secondaryText={null}
        secondaryAction={() => {
          setAssetOnVenueAlert(false);
          setAssociatedVenue(null);
        }}
        visible={assetOnVenueAlert}
        title={'Location landed in venue'}
        primaryAction={() => {
          setAssetOnVenueAlert(false);
          initiateAssociationWithVenue();
        }}
      />

      <ConfirmAlert
        message={`Vendor for ${edittedAsset?.mounting_type} is not selected. Set it in your profile.`}
        primaryText={'Take me there'}
        secondaryText={"I'll do it later"}
        secondaryAction={() => {
          showVendorNotSelected(false);
        }}
        visible={vendorNotSelected}
        title={'Vendor not selected'}
        primaryAction={() => {
          goToProfile();
        }}
      />

      <AssetImagesModal
        images={assetImages ?? []}
        actionType="edit"
        show={showImageModal}
        close={() => {
          setShowImageModal(false);
        }}
        setLoading={setLoading}
        trackingId={edittedAsset?.tracking_id ?? 'NO_TRACKING_ID'}
        handleChange={(value: IAssetImageObj[]) => {
          setAssetImages([...value]);
        }}
        alert={(alertType: 'error' | 'warning' | 'success', message: string) => {
          handleAlertForImages(alertType, message);
        }}
      />

      <Spinner show={loading} />
      <ErrorAlert
        show={error.alert}
        onDismiss={() => {
          setError({ message: '', alert: false });
        }}
        message={error.message}
      />
      <SuccessAlert
        show={success.alert}
        onDismiss={() => {
          setSuccess({ message: '', alert: false });
        }}
        message={success.message}
      />
      <WarningAlert
        show={warning.alert}
        onDismiss={() => {
          setWarning({ message: '', alert: false });
        }}
        message={warning.message}
      />
    </div>
  );
};

export default EditAsset;
