import moment from 'moment';
import './UserActions.css';

interface AssetActionsProps {
  asset: any;
  assetClicked: any;
}
const AssetActions: React.FC<AssetActionsProps> = ({ asset, assetClicked }) => {
  return (
    <div
      className="network-activity-asset-row"
      onClick={() => {
        assetClicked();
      }}>
      <div className="row  vertical-center user-row">
        <div className="col word-align ">
          <span className="user-text px-2">{asset.tracking_id}</span>
        </div>
        <div className="col word-align">
          <span className="user-text">{asset.serial_number}</span>
        </div>
        <div className="col word-align">
          <span className="user-text">{asset.cbrs_node_name}</span>
        </div>
        <div className="col word-align">
          <span className="user-text">{asset.site_id}</span>
        </div>
        <div className="col word-align">
          <span className="user-text">{asset.mounting_type}</span>
        </div>
        <div className="col word-align">
          <span className="user-text">{asset.cbsd_category}</span>
        </div>
        <div className="col word-align">
          <span className="user-text">{asset.username}</span>
        </div>
        <div className="col word-align">
          <span className="user-text">{moment.utc(asset.updation_time).format('MMM Do YYYY HH:mm:ss')}</span>
        </div>
        <div className="col word-align">
          <span className="user-text">{asset.age}</span>
        </div>
      </div>
    </div>
  );
};

export default AssetActions;
