import React from "react";

interface LabelLessinputProps {
  placeholder: string;
  value: string | number | undefined;
  newValue?: any;
  type?: any;
  disabled?: boolean;
  w100?: boolean;
  className?: string;
  name? : string;
  changeVal ? : any
}

const LabelLessInput: React.FC<LabelLessinputProps> = ({
  placeholder,
  value,
  newValue,
  type = "text",
  disabled = false,
  w100 = false,
  className,
  name,
  changeVal
}) => {
  const setValue = (e: any) => {
    if(typeof newValue == 'function')
    newValue(e.target.value);
    if(typeof changeVal  == 'function')
    changeVal(e)
  };
  return (
    <input
      name={name}
      className={`labelless-input ${className} ${w100 ? "w-100" : ""}`}
      autoComplete="new-password"
      placeholder={placeholder}
      value={value}
      onChange={setValue}
      type={type}
      disabled={disabled}
    />
  );
};

export default LabelLessInput;
