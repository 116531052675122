import React, { useEffect, useState } from 'react';
import { CBSDStatuses, DecommissionStatuses } from '../constants/GlobalConstants';
import './DecommissionModal.css';
import { exists } from '../services/helper';
import { color, style } from '@mui/system';
import RadioButton from './RadioButton';

interface DecommissionAssetSummaryProps {
  searchedView: any;
  summary: any;
  refreshAssets: any;
}

const DecommissionAssetNav: React.FC<DecommissionAssetSummaryProps> = ({ searchedView, summary, refreshAssets }) => {
  const [selectedView, setSelectedView] = useState(searchedView);
  useEffect(() => {
    refreshAssets(selectedView);
  }, [selectedView]);

  const handleOnChange = (viewKey: string) => {
    setSelectedView(viewKey ?? DecommissionStatuses.PHYSICALLY_DECOMMISSIONED.key);
  };

  return (
    <div className="ms-2 d-flex gap-4 mt-3">
      <div className="row-wise">
        <div>
          <input
            type="radio"
            id="decommissionRequested"
            name="decommissionView"
            value={DecommissionStatuses.DECOMMISSION_REQUESTED.key}
            checked={selectedView === DecommissionStatuses.DECOMMISSION_REQUESTED.key}
            onChange={() => handleOnChange(DecommissionStatuses.DECOMMISSION_REQUESTED.key)}
          />
        </div>
        <div className="ms-2">
          <label htmlFor="decommissionRequested" style={{ color: selectedView === DecommissionStatuses.DECOMMISSION_REQUESTED.key ? '#0059b2' : '' }}>
            <span>{DecommissionStatuses.DECOMMISSION_REQUESTED.displayText}</span>
            <span className="ms-1 count-show">({summary.requestedDecommissionCount})</span>
          </label>
        </div>
      </div>

      <div className="row-wise">
        <div>
          <input
            type="radio"
            id="physicallyDecommissioned"
            name="decommissionView"
            value={DecommissionStatuses.PHYSICALLY_DECOMMISSIONED.key}
            checked={selectedView === DecommissionStatuses.PHYSICALLY_DECOMMISSIONED.key}
            onChange={() => handleOnChange(DecommissionStatuses.PHYSICALLY_DECOMMISSIONED.key)}
          />
        </div>
        <div className="ms-2">
          <label
            htmlFor="physicallyDecommissioned"
            style={{ color: selectedView === DecommissionStatuses.PHYSICALLY_DECOMMISSIONED.key ? '#0059b2' : '' }}>
            <span>{DecommissionStatuses.PHYSICALLY_DECOMMISSIONED.displayText}</span>
            <span className="ms-1 count-show">({summary.physicallyDecommissionedCount})</span>
          </label>
        </div>
      </div>

      <div className="row-wise">
        <div>
          <input
            type="radio"
            id="decommissioned"
            name="decommissionView"
            value={DecommissionStatuses.DECOMMISSIONED.key}
            checked={selectedView === DecommissionStatuses.DECOMMISSIONED.key}
            onChange={() => handleOnChange(DecommissionStatuses.DECOMMISSIONED.key)}
          />
        </div>
        <div className="ms-2">
          <label htmlFor="decommissioned" style={{ color: selectedView === DecommissionStatuses.DECOMMISSIONED.key ? '#0059b2' : '' }}>
            <span>{DecommissionStatuses.DECOMMISSIONED.displayText}</span>
            <span className="ms-1">({summary.decommissionedCount})</span>
          </label>
        </div>
      </div>
    </div>
  );
};

export default DecommissionAssetNav;
