import moment from 'moment';
import './UserActions.css';

interface AuditlogsActionsProps {
  audit_log: any;
}
const AuditLogData: React.FC<AuditlogsActionsProps> = ({ audit_log }) => {
  const getUnits = (parameter: string) => {
    switch (parameter) {
      case 'Azimuth':
        return '°N';
      case 'HAAT':
        return 'm';
      case 'Height':
        return 'ft';
      case 'Latitude':
      case 'Longitude':
        return '°';
      default:
        return '';
    }
  };
  return (
    <div className="row vertical-center user-row">
      <div className="col">
        <span className="user-text">{audit_log?.parameter}</span>
      </div>
      <div className="col">
        {audit_log?.sector_id && audit_log?.parameter !== 'HAAT' ? (
          <span className="user-text">{audit_log?.sector_id}</span>
        ) : (
          <span className="user-text">{'--'}</span>
        )}
      </div>
      <div className="col">
        {audit_log?.initial_value ? (
          <span className="user-text">{`${audit_log?.initial_value} ${getUnits(audit_log?.parameter)}`}</span>
        ) : (
          <span className="user-text">{'--'}</span>
        )}
      </div>
      <div className="col">
        {audit_log?.final_value ? (
          <span className="user-text">{`${audit_log?.final_value} ${getUnits(audit_log?.parameter)}`}</span>
        ) : (
          <span className="user-text">{'--'}</span>
        )}
      </div>
      <div className="col">
        <span className="user-text">{audit_log?.updated_by}</span>
      </div>
      <div className="col">
        <span className="user-text">{moment.utc(audit_log?.timestamp).format('MMM Do YYYY HH:mm:ss')}</span>
      </div>
    </div>
  );
};

export default AuditLogData;
