import Icon from "@ant-design/icons"

const PenToolSvg = () => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.1195 9.71715L12.9497 7.54745C12.7935 7.39124 12.5403 7.39124 12.3841 7.54745L12.3196 7.61193L11.5452 3.73999C11.4841 3.43453 11.2513 3.19231 10.9484 3.11921L0.628418 0.628174L3.11946 10.9482C3.19255 11.251 3.43477 11.4839 3.74023 11.5449L7.61218 12.3193L7.54769 12.3838C7.39148 12.54 7.39148 12.7933 7.54769 12.9495L9.7174 15.1192C9.87361 15.2754 10.1269 15.2754 10.2831 15.1192L15.1195 10.2828C15.2757 10.1266 15.2757 9.87336 15.1195 9.71715ZM8.73563 12.6667L10.0002 13.9313L13.9315 10L12.6669 8.73539L8.73563 12.6667ZM4.09446 10.5552L2.27269 3.00783L5.70776 6.44291C5.8777 6.13327 6.13352 5.87746 6.44315 5.70752L3.00808 2.27244L10.5554 4.09422L11.4357 8.49574L8.49598 11.4355L4.09446 10.5552Z" fill="currentColor" />
        <path d="M5.48024 7.33333C5.48024 7.01061 5.56272 6.70716 5.70776 6.44291C5.8777 6.13327 6.13352 5.87746 6.44315 5.70752C6.70741 5.56248 7.01086 5.48 7.33357 5.48C8.35714 5.48 9.18691 6.30976 9.18691 7.33333C9.18691 8.3569 8.35714 9.18666 7.33357 9.18666C6.31001 9.18666 5.48024 8.3569 5.48024 7.33333ZM6.75093 6.76585C6.60818 6.91239 6.52024 7.11259 6.52024 7.33333C6.52024 7.78252 6.88438 8.14666 7.33357 8.14666C7.78277 8.14666 8.14691 7.78252 8.14691 7.33333C8.14691 6.88414 7.78277 6.52 7.33357 6.52C7.11284 6.52 6.91264 6.60793 6.7661 6.75068L6.75859 6.75834L6.75093 6.76585Z" fill="currentColor" />
    </svg>
)

const PenToolIcon = (props : any) => (
    <Icon component={PenToolSvg} {...props} />
)

interface iconProps {
    className? : string
    style? : any
}

 export const PenIcon = (props : iconProps) => {
    return (
    <PenToolIcon className = {props.className} style = {props.style} />
    )
 }