import AssetDotRMA from '../assets/assetDotRma.png';
import AssetDotSAS from '../assets/assetDotSas.png';
import AssetDotCloud from '../assets/assetDotCloud.png';
import AssetDotFtech from '../assets/assetDotFtech.png';
import Assetdecomrequested from '../assets/assetDotRmaProgress.png';
import AssetDotPreloaded from '../assets/assetDotPreloaded.png';
import WifiIcon from '../assets/wifi_icon.png';
import WifiIconCloud from '../assets/wifi_icon_cloud.png';
import OpenWifiIcon from '../assets/wifi_ow_icon.png';
import AssetPdecom from '../assets/circle_physical_decomm.png';
import AssetDecom from '../assets/circle_decomm.png';

{
  /** Interface definitions */
}
interface AssetStatus {
  key: string;
  value: string;
  displayText: string;
}

{
  /** Constant definitions */
}
export const UserRoles = {
  INSTALLER: 'Installer',
  CPI: 'CPI',
  ADMIN: 'Master Admin',
};

export const CBSDStatuses = {
  CLOUD: { key: 'CLOUD', value: 'Cloud', displayText: 'CLOUD' },
  SAS: { key: 'SAS', value: 'SAS', displayText: 'SAS' },
  RMA_REQUESTED: { key: 'RMA_REQUESTED', value: 'RMA Requested', displayText: 'RMA' },
  DECOMMISSION: { key: 'DECOMMISSION', value: 'Decommission', displayText: 'DECOMMISSION' },
  RMA_INPROGRESS: { key: 'RMA_INPROGRESS', value: 'RMA in progress', displayText: 'RMA_PROGRESS' },
  FIELD_TECH: { key: 'FIELD_TECH', value: 'Field Tech', displayText: 'FIELD TECH' },
  PRELOADED: { key: 'PRELOADED', value: 'Preloaded', displayText: 'PRELOADED' },
};

export const CBSDMountingTypes = {
  AERIAL: 'Aerial',
  ROE: 'ROE',
  SMB: 'SMB',
};

export const DecommissionStatuses = {
  PHYSICALLY_DECOMMISSIONED: { key: 'PHYSICALLY_DECOMMISSIONED', value: 'Physically Decommissioned', displayText: 'Physically Decommissioned' },
  DECOMMISSION_REQUESTED: { key: 'DECOMMISSION_REQUESTED', value: 'Decommission Requested', displayText: 'Requested' },
  DECOMMISSIONED: { key: 'DECOMMISSIONED', value: 'Decommissioned', displayText: 'Decommissioned' },
};

export const UploadAssetsNav = {  
  PRELOAD_ASSETS: { key: 'PRELOAD_ASSETS', value: 'Preload Assets', displayText: 'Preload Assets' },
  DECOMMISSION_ASSETS: { key: 'DECOMMISSION_ASSETS', value: 'Decommission Assets', displayText: 'Decommission Assets' },
}

export const CBSDAge = {
  ZeroFive: '0-5',
  SixTen: '6-10',
  ElevenTwenty: '11-20',
  GtTwenty: '>20',
};

export const assetStatusDot: any = {
  'Field Tech': AssetDotFtech,
  Cloud: AssetDotCloud,
  SAS: AssetDotSAS,
  OpenWifi: AssetDotSAS,
  'RMA Requested': AssetDotRMA,
  'RMA in progress': AssetDotFtech,
  'Decommission Requested': Assetdecomrequested,
  'Physically Decommissioned': AssetPdecom,
  Decommissioned: AssetDecom,
  Preloaded: AssetDotPreloaded,
};

export const wifiStatusIcon: any = {
  'Field Tech': WifiIcon,
  Cloud: WifiIconCloud,
  OpenWifi: OpenWifiIcon,
};
