import React from 'react';
import Dropzone from 'react-dropzone';
import ImageResizer from 'react-image-file-resizer';
import CameraRetake from '../../assets/camera-retake.svg';
import IAssetImageObj from '../../constants/assetImageObject';
import { ApiHandler } from '../../services/apiHandler';
import Modal from '../Modal';
import PrimaryButton from '../PrimaryButton';
import './_assetimagesmodal.css';
import { IAssetImagesModalProps } from './IAssetImagesModalProps';

const AssetImagesModal: React.FC<IAssetImagesModalProps> = ({ images, show, close, setLoading, trackingId, handleChange, alert, actionType }) => {
  const getLocationAndUpload = () => {
    navigator.geolocation.getCurrentPosition(
      (position: GeolocationPosition) => {
        initiateUploadImages(position.coords.latitude, position.coords.longitude);
      },
      (e: any) => {
        console.log('Error fetching user location: ', e);
        alert('error', 'Error fetching user location');
      },
      { enableHighAccuracy: true, timeout: 3000 }
    );
  };

  const uploadImageApi = (image: any, latitude: any, longitude: number, imageDataUrl: any) => {
    let requestBody: any = new FormData();
    requestBody.append('file', imageDataUrl);
    requestBody.append('type', `${image.type}`);
    requestBody.append('ext', image.image[0]['path'].slice(image.image[0]['path'].lastIndexOf('.') + 1));
    requestBody.append('tracking_id', trackingId.toString());
    requestBody.append('latitude', latitude);
    requestBody.append('longitude', longitude);

    ApiHandler({ apiName: 'upload', params: {}, body: requestBody })
      .then((response: any) => {
        setLoading(false);
        alert('success', response.data.message);
        image.path = response.data.path;
        image.uploaded = true;
        if (actionType === 'edit') image.edited = true;
        image.latitude = latitude;
        image.longitude = longitude;
      })
      .catch((error: any) => {
        console.log('Error in uploading image', error);
        alert('error', error.data.message);
        setLoading(false);
      });
  };

  const initiateUploadImages = (latitude: number, longitude: number) => {
    let finalImages: IAssetImageObj[] = [...images];
    finalImages.forEach(async (image: IAssetImageObj, index: number) => {
      if (image.image?.length > 0 && !image.uploaded) {
        setLoading(true);
        ImageResizer.imageFileResizer(
          image.image[0],
          800,
          800,
          'JPEG',
          100,
          0,
          (uri: any) => {
            uploadImageApi(image, latitude, longitude, uri);
          },
          'base64'
        );
      }
    });
    handleChange(finalImages);
  };

  const handleImageSelection = (imageObj: IAssetImageObj, file: any, index: number) => {
    imageObj.image = file;
    imageObj.uploaded = false;
    imageObj.edited = false;
    let newImages = [...images];
    newImages[index] = imageObj;
    handleChange(newImages);
    getLocationAndUpload();
  };

  return (
    <Modal show={show}>
      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
        <div className="modal-content app-card border-0 p-0">
          <div className="modal-header">
            <h5 className="modal-title">{'IMAGES'}</h5>
            <button
              type="button"
              className="btn-close"
              onClick={(e: any) => {
                e.preventDefault();
                close();
              }}></button>
          </div>
          <div className="modal-body overflow-auto">
            {images.map((image: IAssetImageObj, index: number) => {
              return (
                <div key={`${index}-${image.type}`}>
                  {image.type === 'MISCELLANEOUS_1' ? <label className="mb-2 asset-image-title">{'OTHERS'}</label> : <></>}
                  {image.type === 'RADIO_LABEL' ? <label className="mb-2 asset-image-title">{'ASSET'}</label> : <></>}
                  <Dropzone
                    multiple={false}
                    onDrop={(acceptedFiles: any) => {
                      handleImageSelection(image, acceptedFiles, index);
                    }}
                    accept={{ 'image/jpg': ['.jpg'], 'image/jpeg': ['.jpeg'], 'image/png': ['.png'] }}>
                    {({ getRootProps, getInputProps }) => (
                      <section className={`d-flex align-items-center w-100 px-3 mb-3 ${image.uploaded ? 'img-uploaded' : 'img-default'}`}>
                        <div
                          {...getRootProps({
                            className: `w-100`,
                          })}>
                          <input {...getInputProps()} />

                          <div className="d-flex flex-row align-items-center gap-3 justify-content-between">
                            <div className="d-flex flex-row align-items-center">
                              {image.uploaded ? (
                                <span style={{ color: '#008000' }} className="material-icons-round me-2">
                                  check_circle
                                </span>
                              ) : (
                                <></>
                              )}
                              {!['MISCELLANEOUS_1', 'MISCELLANEOUS_2', 'MISCELLANEOUS_3', 'MISCELLANEOUS_4'].includes(image.type) ? (
                                <span className="image-label">{image?.label}</span>
                              ) : (
                                <></>
                              )}
                              {['MISCELLANEOUS_1', 'MISCELLANEOUS_2', 'MISCELLANEOUS_3', 'MISCELLANEOUS_4'].includes(image.type) && image.uploaded ? (
                                <span>Uploaded!</span>
                              ) : ['MISCELLANEOUS_1', 'MISCELLANEOUS_2', 'MISCELLANEOUS_3', 'MISCELLANEOUS_4'].includes(image.type) ? (
                                <span>{'Select a photo'}</span>
                              ) : (
                                <></>
                              )}
                            </div>

                            {image.uploaded ? (
                              <img src={CameraRetake} />
                            ) : (
                              <span style={{ color: '#737373' }} className="material-icons-round">
                                add_a_photo
                              </span>
                            )}
                          </div>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
              );
            })}
          </div>
          <div className="modal-footer">
            <PrimaryButton
              label={'Done'}
              clicked={(e: any) => {
                e.preventDefault();
                close();
              }}
              // disable={images.filter((image: IAssetImageObj) => image.image?.length === 0 && !image.uploaded).length > 0}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AssetImagesModal;
